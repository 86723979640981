import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverCloseButton,
  Avatar,
  Text,
  Flex,
  Box,
} from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { formateDeleteDate } from 'helpers/utils'
import { useNodeDetails } from 'features/node/hooks'
import { TagsSection } from 'features/tags/components'
import { AvatarsTooltip } from 'components/elements'
import { EntityType, Node } from 'types/graphqlSchema'
import React from 'react'

type NodeInfoPopOverProps = {
  node: Node
  setOpenModal?: (name: string) => void
  children: React.ReactNode
}

function NodeInfoPopover({ node, setOpenModal, children }: NodeInfoPopOverProps) {
  const {
    type,
    itemName,
    ownerName,
    ownerUrl,
    members,
    totalSize,
    lastModifiedBy,
    createdDate,
    updatedDate,
    updatedAt,
  } = useNodeDetails(node)
  const { pathname } = useLocation()

  return (
    <>
      <Popover isLazy placement="auto" closeOnBlur>
        <PopoverTrigger>
          <Button
            variant="transparentButton"
            p={0}
            _focusVisible={{ boxShadow: '0 0 0 1px var(--chakra-colors-borderFocus)' }}
          >
            {children}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          p={4}
          bg="textHighlight"
          border="1px"
          borderColor="textSoft"
          color="textBlack"
          onClick={(e) => e.stopPropagation()}
        >
          <PopoverCloseButton mr="8px" mt="20px" />
          <PopoverHeader borderBottom="1px" borderColor="textSoft" pb="16px">
            {itemName || 'Untitled'}
          </PopoverHeader>
          <PopoverBody display="flex" flexDir="column" px={0} color="textBlack">
            {pathname.includes('trash') && (
              <Text fontSize="15px" color="alert" mb={6}>
                {`This ${type?.toLowerCase()} will be permanently deleted on ${formateDeleteDate(updatedAt, 1)}`}
              </Text>
            )}
            <Avatar size="sm" name={ownerName} src={ownerUrl} border="1px solid white" mb={2} />
            <Text mb={6} fontSize="15px" fontWeight="normal" color="textRegular">
              Owner{' '}
              <Text as="span" color="textBlack">
                {ownerName}
              </Text>
            </Text>
            <Box mb={2}>
              <AvatarsTooltip
                users={members}
                max={9}
                size="sm"
                clickAction={() => setOpenModal && setOpenModal('share')}
              />
            </Box>
            <Text fontSize="15px" fontWeight="normal" color="textRegular" mb="8px">
              Shared with
            </Text>
            <Flex
              flexDir="column"
              w="fill"
              px={0}
              py="8px"
              borderTop="1px"
              borderBottom="1px"
              borderColor="textSoft"
              fontSize="15px"
            >
              <Text mb={4} color="textRegular">
                Created{' '}
                <Text as="span" color="textBlack">
                  {createdDate}
                </Text>
              </Text>
              <Text mb={4} color="textRegular">
                Last Modified <Text as="span" color="textBlack">{`${updatedDate} by ${lastModifiedBy}`}</Text>
              </Text>
              <Flex direction="row" justify="space-between">
                <Text flex={1} color="textRegular">
                  Size{' '}
                  <Text as="span" color="textBlack">
                    {totalSize}
                  </Text>
                </Text>
              </Flex>
            </Flex>
            <TagsSection entity={node} entityType={EntityType.Node} isEditable={false} />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  )
}

export default NodeInfoPopover
