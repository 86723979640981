import { gql } from '@apollo/client'

export const GET_TRACKING_EVENTS = gql`
  query GetTrackingEvents($entityId: String, $entityType: EntityType, $eventType: String) {
    getTrackingEvents(entityId: $entityId, entityType: $entityType, eventType: $eventType) {
      id
      entityId
      entityType
      eventType
      eventUrl
      geoIP
      referralURL
      userAgent
      userId
      user {
        id
        firstName
        lastName
        email
        avatar
        avatarSecureUrl
      }
      customFields
      createdAt
    }
  }
`

export const GET_RECENT_VIEWED_PROJECTS = gql`
  query GetRecentViewedProjects($count: Int) {
    getRecentViewedProjects(count: $count) {
      id
      name
      coverImage
      coverImageSecureUrl
      color
      category
      organizationId
      ownerId
      owner {
        id
        firstName
        lastName
        email
        avatar
        avatarSecureUrl
      }
      private
      state
      tags {
        id
        name
      }
      totalSize
      type
      updatedAt
      updatedBy {
        id
        firstName
        lastName
        email
        avatarSecureUrl
        avatar
      }
      updatedById
      createdAt
    }
  }
`

export const GET_USER_MENTIONS_EVENTS = gql`
  query GetUserMentionsEvents($isRead: Boolean) {
    getUserMentionsEvents(isRead: $isRead) {
      id
      eventType
      entityType
      entityId
      customFields
      createdAt
      receiverId
      receiver {
        id
        firstName
        lastName
        email
        avatar
        avatarSecureUrl
      }
      state
      isRead
      eventUrl
      updatedAt
      userId
      user {
        id
        firstName
        lastName
        email
        avatar
        avatarSecureUrl
      }
    }
  }
`

export const ADD_TRACKING_EVENTS = gql`
  mutation AddTrackingEvents($events: [TrackingEventAddInput]!) {
    addTrackingEvents(events: $events) {
      id
      entityId
      entityType
      eventType
      eventUrl
      userId
      userAgent
      geoIP
      referralURL
      customFields
      createdAt
    }
  }
`

export const UPDATE_TRACKING_EVENT = gql`
  mutation UpdateTrackingEvent($input: TrackingEventUpdateInput!) {
    updateTrackingEvent(input: $input) {
      error
      trackingEvent {
        id
        entityId
        entityType
        state
        isRead
      }
    }
  }
`

export const UPDATE_USER_TRACKING_EVENTS = gql`
  mutation UpdateUserTrackingEvents($input: UserTrackingEventsInput!) {
    updateUserTrackingEvents(input: $input)
  }
`
