import { useState } from 'react'
import { Divider, Flex, Grid, Box } from '@chakra-ui/react'
import { useNodeDetails, useNodeMenuActions } from 'features/node'
import { useAppSlice } from 'features/redux'
import { PrivacySwitch } from 'components'
import { TagsSection } from 'features/tags/components'
import { EntityType } from 'types/graphqlSchema'
import UserInfo from './UserInfo'
import NodeData from './NodeData'
import ShareModalV2 from '../Modal/ShareModalV2'
import { menuActions } from 'helpers/constant'

function InfoPanelDetails({ node }) {
  const { user } = useAppSlice()
  const { totalSize, lastModifiedBy, createdDate, updatedDate, ownerName, members, ownerUrl, id, type } =
    useNodeDetails(node)
  const [isPrivate, setIsPrivate] = useState(!!node.private)

  const [openShareModal, setOpenShareModal] = useState<string>('')

  const ownerIsCurrentUser = user.id === node.ownerId
  const showPrivacySwitch = node && !node?.parent?.private
  const { updateNode } = useNodeMenuActions()

  const handlePrivacyChange = async (privacySetting: boolean) => {
    if (isPrivate !== privacySetting) {
      setIsPrivate(privacySetting)
      await updateNode({ private: privacySetting }, node)
    }
  }

  return (
    <>
      <Grid
        gridTemplateColumns={'repeat(5, auto)'}
        h="auto"
        gap={{ base: '1rem', '2xl': '2.5rem' }}
        maxW="90%"
        maxH="40dvh"
      >
        <Box>
          <UserInfo
            nodeType={type}
            nodeId={id}
            ownerIsCurrentUser={ownerIsCurrentUser}
            ownerName={ownerName}
            ownerUrl={ownerUrl}
            members={members}
            setOpenShareModal={setOpenShareModal}
          />
          {ownerIsCurrentUser && showPrivacySwitch && (
            <PrivacySwitch user={user} node={node} onPrivacyChange={handlePrivacyChange} darkBg />
          )}
        </Box>
        <Divider orientation="vertical" />
        <NodeData
          createdDate={createdDate}
          lastModifiedBy={lastModifiedBy}
          totalSize={totalSize}
          updatedDate={updatedDate}
        />
        <Divider orientation="vertical" />
        {node && (
          <Flex fontWeight={400}>
            <TagsSection entity={node} entityType={EntityType.Node} paddingY="0px" darkBg />
          </Flex>
        )}
      </Grid>
      <ShareModalV2
        isOpen={openShareModal === menuActions.share}
        onClose={() => setOpenShareModal('')}
        node={node}
        setOpenModal={setOpenShareModal}
      />
    </>
  )
}

export default InfoPanelDetails
