import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useStorage } from 'hooks'
import { useTipRedirect } from 'features/tip/hooks'
import {
  useAppSlice,
  useNodeSlice,
  useProjectSlice,
  useBoxSlice,
  useTipSlice,
  useNotificationSlice,
  useLinkShareSlice,
  useSearchSlice,
} from 'features/redux'
import { GET_TRASH_NODES, PERMANENTLY_DELETE_NODES, RESTORE_ALL_TRASH_NODES, LEAVE_SHARED_NODE } from 'features/graphql'
import { validate, regs } from 'helpers/vali'
import {
  // uploadFile,
  getResizedImage,
  // invalidateCoverImage,
} from 'helpers/storage'
import { nodeType } from 'helpers/constant'
// import usePhotoCompressor from 'hooks/usePhotoCompressor'
import { Toast } from 'components'
import { Node, NodeState, NodeType, User } from 'types/graphqlSchema'

export default function useNodeMenuActions() {
  const navigate = useNavigate()
  const { download } = useStorage()
  const {
    dispatch,
    user,
    isUploading,
    setIsLoading,
    updateFollowing,
    updateNotifications,
    updateAllNotifications,
    updatedShareEmailSuggestions,
  } = useAppSlice()
  const {
    currentProject,
    getProject,
    getProjects,
    deleteProject,
    updateProject,
    removeProject,
    duplicateProject,
    updateProjectInfo,
    updateSharedMembers,
    updateProjectLinkShare,
    removeProjectLinkShare,
    removeProjectChildren,
  } = useProjectSlice()
  const {
    getBox,
    duplicateBox,
    updateBox,
    removeBox,
    deleteBox,
    removeBoxChildren,
    updateBoxShareMembers,
    updateBoxLinkShare,
    removeBoxLinkShare,
  } = useBoxSlice()
  const {
    createNewTip,
    duplicateTip,
    updateTip,
    deleteTip,
    removeTip,
    updateTipShareMembers,
    updateTipLinkShare,
    removeTipLinkShare,
  } = useTipSlice()
  const {
    getNode,
    updateNode: updateNodeCoverImage,
    createNodeShareMembers,
    updateNodeShareMember,
    unshareNodeShareMember,
  } = useNodeSlice()
  const { notificationAsRead, allNotificationsAsRead, allFollowNotificationsAsRead } = useNotificationSlice()
  const { deleteNodeLinkShare, updateNodeLinkShare } = useLinkShareSlice()
  const { updateSearchList, updateSearchNodeName } = useSearchSlice()
  const { tipRedirect } = useTipRedirect()
  // const { compressor } = usePhotoCompressor()

  const [openModal, setOpenModal] = useState('')
  const [, setErrorMessage] = useState('')

  // GraphQL Calls
  const [mutateRestoreAllTrashNodes] = useMutation(RESTORE_ALL_TRASH_NODES, {
    onError(error) {
      setErrorMessage(error.message)
    },
    refetchQueries: [{ query: GET_TRASH_NODES }, 'trashNodes'],
  })
  const [mutatePermanentlyDeleteNodes] = useMutation(PERMANENTLY_DELETE_NODES, {
    onError(error) {
      setErrorMessage(error.message)
    },
  })
  const [mutateLeaveSharedNode] = useMutation(LEAVE_SHARED_NODE, {
    onError(error) {
      setErrorMessage(error.message)
    },
  })

  // Helpers

  const createUrlPath = (node: Node, pathname: string) => {
    const { id, project, breadcrumbs } = node
    let redirectPath = ''
    if (pathname.includes('project') || pathname.includes('personal')) {
      redirectPath = `${pathname}/${id}`
    } else {
      if (node.type === 'PROJECT') {
        redirectPath = `project/${node.id}`
      } else if (node.type === 'BOX') {
        if (breadcrumbs && breadcrumbs.length > 0) {
          const path = breadcrumbs.map((breadcrumb) => breadcrumb?.id)
          const idPath = path.join('/')
          if (project?.id === user.myProject.id) {
            redirectPath = `personal/${idPath}`
          } else {
            redirectPath = `project/${idPath}/${node.id}`
          }
        } else {
          redirectPath = `project/${project?.id}/${node.id}`
        }
      } else {
        // node is file
        redirectPath = project?.id ? `project/${project?.id}/file/${node.id}` : `file/${node.id}`
      }
    }
    return redirectPath
  }

  // Actions
  const updateNode = async (updatedValue: Partial<Node>, node: Node, pageType = '') => {
    if (!node) return
    const params = { ...updatedValue, id: node.id }
    try {
      switch (node.type) {
        case NodeType.Tip:
          await dispatch(updateTip(params))
          break
        case NodeType.Box:
          await dispatch(updateBox(params))
          break
        case NodeType.Project:
          await dispatch(updateProject(params))
          break
        default:
          break
      }
      if (pageType === 'search') {
        dispatch(updateSearchNodeName({ name: updatedValue.name, id: node.id }))
      }
    } catch (error) {
      setErrorMessage(error)
    }
  }

  const deleteNode = async (node: Node, pageType = '') => {
    try {
      switch (node.type) {
        case nodeType.tip:
          if (pageType === 'search') {
            await dispatch(deleteTip(node.id))
            break
          }
          dispatch(removeTip(node.id))
          // Removing for now to stop layout jump on Delete Files / Folders
          // Is this needed?
          // if (node.parent?.type === 'PROJECT') {
          //   dispatch(removeProjectChildren({ nodeId: node.id, nodeType: node.type }))
          // } else {
          //   dispatch(removeBoxChildren({ nodeId: node.id, nodeType: node.type }))
          // }
          await dispatch(deleteTip(node.id))
          break
        case nodeType.box:
          if (pageType === 'search') {
            await dispatch(deleteBox(node.id))
            break
          }
          dispatch(removeBox(node.id))
          if (node.parent?.type === 'PROJECT') {
            dispatch(removeProjectChildren({ nodeId: node.id, nodeType: node.type }))
          } else {
            dispatch(removeBoxChildren({ nodeId: node.id, nodeType: node.type }))
          }
          await dispatch(deleteBox(node.id))
          break
        case nodeType.project:
          if (pageType === 'search') {
            await dispatch(deleteProject(node.id))
            break
          }
          dispatch(removeProject(node.id))
          await dispatch(deleteProject(node.id))
          break
        default:
          break
      }
      if (isUploading) {
        window.stop()
      }
    } catch (error) {
      setErrorMessage(error)
    }
  }

  const permanentlyDeleteNode = () => {
    mutatePermanentlyDeleteNodes({
      refetchQueries: [{ query: GET_TRASH_NODES }, 'trashNodes'],
    })
  }

  const restoreAllNodes = () => {
    mutateRestoreAllTrashNodes({
      refetchQueries: [{ query: GET_TRASH_NODES }, 'trashNodes'],
    })
  }

  const undoCreation = async (nodeIds: string[]) => {
    await mutatePermanentlyDeleteNodes({ variables: { nodeIds } })
  }

  const handleRemoveCoverImage = async (id?: string) => {
    if (!id) return
    const result = await dispatch(updateNodeCoverImage({ id, coverImage: '' }))
    if (result.payload) dispatch(updateProjectInfo(result.payload))
  }

  const shareNode = async (usersEmails: string, nodeId: string, message = '', pageType = '') => {
    const emailsArray = usersEmails.split(',')
    const emails = emailsArray.map((email) => email.replace(/\s/g, ''))
    const valiEmails = emails.filter((email) => {
      const result = validate({ email }, regs)
      return !result.isError
    })
    if (valiEmails.length === 0) return {}

    const result = await dispatch(
      createNodeShareMembers({
        nodeId,
        userEmails: valiEmails,
        message,
      }),
    )
    if (!result.payload) return {}
    if (result.payload.type === 'PROJECT') {
      dispatch(updateSharedMembers(result.payload))
    }
    if (result.payload.type === 'BOX') {
      dispatch(updateBoxShareMembers(result.payload))
    }
    if (result.payload.type === 'TIP') {
      dispatch(updateTipShareMembers(result.payload))
    }
    if (pageType === 'search') {
      dispatch(updateSearchList(result.payload))
    }
    return result.payload
  }

  const updateShareNode = async (userId: string, nodeId: string, shareType: string, pageType = '') => {
    const result = await dispatch(
      updateNodeShareMember({
        nodeId,
        userId,
        shareType: shareType.toUpperCase(),
      }),
    )

    if (!result.payload) return {}

    if (result.payload.type === 'PROJECT') {
      dispatch(updateSharedMembers(result.payload))
    }

    if (result.payload.type === 'BOX') {
      dispatch(updateBoxShareMembers(result.payload))
    }

    if (result.payload.type === 'TIP') {
      dispatch(updateTipShareMembers(result.payload))
    }

    if (pageType === 'search') {
      dispatch(updateSearchList(result.payload))
    }
    return result.payload
  }

  const unshareNode = async (userId: string, nodeId: string) => {
    const result = await dispatch(
      unshareNodeShareMember({
        nodeId,
        userId,
      }),
    )

    if (!result.payload) return {}

    if (result.payload.type === 'PROJECT') {
      if (userId === user.id) {
        dispatch(removeProject(result.payload))
        dispatch(updateSharedMembers(result.payload))
      } else {
        dispatch(updateSharedMembers(result.payload))
      }
    }

    if (result.payload.type === 'BOX') {
      if (userId === user.id) {
        dispatch(removeBox(result.payload))
        dispatch(updateTipShareMembers(result.payload))
      } else {
        dispatch(updateBoxShareMembers(result.payload))
      }
    }

    if (result.payload.type === 'TIP') {
      if (userId === user.id) {
        dispatch(removeTip(result.payload))
        dispatch(updateTipShareMembers(result.payload))
      } else {
        dispatch(updateTipShareMembers(result.payload))
      }
    }
    return result.payload
  }

  const leaveSharedNode = async (nodeId: string) => {
    return await mutateLeaveSharedNode({ variables: { nodeId } })
  }

  const handleLeaveSharedNode = async (id: string) => {
    try {
      const { payload: node } = await dispatch(getNode(id))
      if (!node) return

      const {
        id: nodeId,
        projectId,
        parent: { id: parentId, type: parentType },
      } = node

      await leaveSharedNode(nodeId)

      const parentFolderIsShared =
        parentType === 'BOX'
          ? await dispatch(getBox(parentId)).then(({ payload: folder }) =>
              folder.sharedMembers.some((sm) => sm.sharedWith.id === user.id),
            )
          : false

      const projectIsShared = await dispatch(
        getProjects({
          filter: {
            type: 'multi_conditions',
            conditions: { type: 'PROJECT', state: 'ACTIVE' },
          },
        }),
      ).then(({ payload: projects }) => projects.some((proj) => proj.id === projectId))

      if (parentFolderIsShared) {
        navigate(`/project/${projectId}/${parentId}`)
      } else if (projectIsShared) {
        navigate(`/project/${projectId}`)
      } else {
        navigate('/')
      }
    } catch (error) {
      console.error('Error leaving shared node:', error)
    }
  }

  const downloadNode = async (node: Node) => {
    try {
      await download(node)
    } catch (error) {
      Toast.show({
        icon: 'error',
        message: `Something went wrong with downloading ${node ? node.name : 'item'}`,
      })
    }
  }

  const duplicateNode = async (nodeId: string, type: string) => {
    switch (type) {
      case 'project':
        await dispatch(duplicateProject(nodeId))
        break
      case 'box':
        await dispatch(duplicateBox(nodeId))
        break
      case 'tip':
        await dispatch(duplicateTip(nodeId))
        break
      default:
        break
    }
  }

  const redirectToParentNode = (node: Node) => {
    const { project, parent } = node
    if (node.type === 'PROJECT' || !project || !parent) return {}
    dispatch(setIsLoading(true))
    if (parent.type === 'BOX') {
      dispatch(getBox(parent.id))
    } else if (parent.type === 'PROJECT') {
      dispatch(getProject(project.id))
    }
    const redirectPath = createUrlPath(node, '')
    return redirectPath
  }

  const createNewTipNode = async (user: User, project: Node | undefined, currentBox: Node | undefined) => {
    const name = ''
    const state = NodeState.Pending
    const type = nodeType.tip
    const defaultProject = project ?? user.myProject
    if (!defaultProject) return
    const projectId = defaultProject.id
    const parentId = currentBox?.id || projectId
    const updatedById = user.id
    const tipInput = {
      name,
      state,
      type,
      projectId,
      parentId,
      updatedById,
      setCurrentTip: true,
    }
    try {
      const {
        payload: { createNode },
      } = await dispatch(createNewTip(tipInput))
      return createNode
    } catch (error) {
      setErrorMessage(error)
    }
  }

  const navigateToBox = async (nodeId: string, projectInfo: NavigateProps | undefined = undefined) => {
    let box
    if (projectInfo) {
      if (!currentProject || currentProject.id !== projectInfo.projectId) {
        dispatch(getProject(projectInfo.projectId))
      }
      dispatch(getBox(nodeId))
    } else {
      box = await dispatch(getBox(nodeId))
      if (!currentProject || currentProject.id !== box?.payload.project.id) {
        dispatch(getProject(box?.payload.project.id))
      }
    }
    const projectId = projectInfo ? projectInfo.projectId : box?.payload.project.id
    navigate(`/project/${projectId}/${nodeId}`)
  }

  const navigateTo = async (
    navigateNodeType: string,
    nodeId: string,
    projectInfo: NavigateProps | undefined = undefined,
    notificationType: string | undefined = undefined,
  ) => {
    dispatch(setIsLoading(true))
    switch (navigateNodeType) {
      case 'project':
        dispatch(getProject(nodeId))
        navigate(`/project/${nodeId}`)
        break
      case 'box':
        navigateToBox(nodeId, projectInfo)
        break
      case 'tip':
        tipRedirect(nodeId, true, true, notificationType)
        dispatch(setIsLoading(false))
        break
      default:
        break
    }
  }

  const markNotificationAsRead = async (notificationId: string) => {
    const result = await dispatch(notificationAsRead(notificationId))
    if (result.payload) {
      dispatch(updateNotifications(result.payload))
    }
  }

  const markAllNotificationsAsRead = async () => {
    const results = await dispatch(allNotificationsAsRead())
    if (results.payload) {
      dispatch(updateAllNotifications(results.payload))
    }
  }

  const markAllFollowNotificationsAsRead = async () => {
    const results = await dispatch(allFollowNotificationsAsRead())
    if (results.payload) {
      dispatch(updateAllNotifications(results.payload))
    }
  }

  const updateFollowedNode = async (nodeId: string) => {
    try {
      dispatch(updateFollowing(nodeId))
    } catch (error) {
      setErrorMessage(error)
    }
  }

  const getAvatarSignedUrl = async (users: INodeUser[]) => {
    const userWithSignedUrl = await Promise.all(
      users.map(async (user) => {
        if (!user) return user
        if (user?.avatar) {
          return {
            ...user,
            avatarUrl: getResizedImage(user.avatar, 'profile.main'),
          }
        }
        return user
      }),
    )
    dispatch(updatedShareEmailSuggestions(userWithSignedUrl))
    return userWithSignedUrl
  }

  const updateReduxLinkShare = (
    parentType: string,
    linkShare: {
      nodeType: 'PROJECT' | 'BOX' | 'TIP'
    },
  ) => {
    switch (linkShare.nodeType) {
      case 'PROJECT':
        dispatch(updateProjectLinkShare(linkShare))
        break
      case 'BOX':
        dispatch(updateBoxLinkShare(linkShare))
        if (parentType === 'PROJECT') {
          dispatch(updateProjectLinkShare(linkShare))
        }
        break
      case 'TIP':
        dispatch(updateTipLinkShare(linkShare))
        if (parentType === 'PROJECT') {
          dispatch(updateProjectLinkShare(linkShare))
        }
        if (parentType === 'BOX') {
          dispatch(updateBoxLinkShare(linkShare))
        }
        break
      default:
        console.warn(`Unexpected nodeType: ${linkShare.nodeType}`)
        break
    }
  }

  const removeReduxLinkShare = (nodeLinkId: string, nodeLinkType: string, parentType: string) => {
    switch (nodeLinkType) {
      case 'PROJECT':
        dispatch(
          removeProjectLinkShare({
            nodeId: nodeLinkId,
            nodeType: nodeLinkType,
          }),
        )
        break
      case 'BOX':
        dispatch(
          removeBoxLinkShare({
            nodeId: nodeLinkId,
            nodeType: nodeLinkType,
          }),
        )
        if (parentType === 'PROJECT') {
          dispatch(
            removeProjectLinkShare({
              nodeId: nodeLinkId,
              nodeType: nodeLinkType,
            }),
          )
        }
        break
      case 'TIP':
        dispatch(
          removeTipLinkShare({
            nodeId: nodeLinkId,
            nodeType: nodeLinkType,
          }),
        )
        if (parentType === 'PROJECT') {
          dispatch(
            removeProjectLinkShare({
              nodeId: nodeLinkId,
              nodeType: nodeLinkType,
            }),
          )
        }
        if (parentType === 'BOX') {
          dispatch(
            removeBoxLinkShare({
              nodeId: nodeLinkId,
              nodeType: nodeLinkType,
            }),
          )
        }
        break
      default:
        break
    }
  }

  const updateLinkShare = async (inputs: ILinkShareInputs, parentType: string) => {
    const { payload } = await dispatch(updateNodeLinkShare(inputs))
    if (!payload) return payload
    updateReduxLinkShare(parentType, payload)
    return payload
  }

  const deleteLinkShare = async (nodeId: string, nodeLinkType: string, parentType = '') => {
    const { payload } = await dispatch(deleteNodeLinkShare(nodeId))
    removeReduxLinkShare(nodeId, nodeLinkType, parentType)
    return payload
  }

  return {
    openModal,
    setOpenModal,
    updateNode,
    deleteNode,
    permanentlyDeleteNode,
    restoreAllNodes,
    undoCreation,
    handleRemoveCoverImage,
    shareNode,
    unshareNode,
    leaveSharedNode,
    handleLeaveSharedNode,
    updateShareNode,
    downloadNode,
    duplicateNode,
    redirectToParentNode,
    createUrlPath,
    createNewTipNode,
    navigateTo,
    updateFollowedNode,
    markNotificationAsRead,
    markAllNotificationsAsRead,
    markAllFollowNotificationsAsRead,
    getAvatarSignedUrl,
    updateLinkShare,
    deleteLinkShare,
  }
}
