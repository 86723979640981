import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ApolloError, useLazyQuery, useMutation, useSubscription } from '@apollo/client'
import { pathPrefix } from 'helpers/constant'
import { GET_FLOW, GET_FLOW_WITH_DESCENDANTS, FLOW_SUBSCRIPTION, UPDATE_FLOW } from 'features/graphql'
import { Flow, FlowUpdateInput } from 'types/graphqlSchema'
import { useFlowFilters } from '.'

export default () => {
  const { todoFlows } = useFlowFilters()
  const navigate = useNavigate()
  const [flow, setFlow] = useState<Flow>()
  const [ancestorFlows, setAncestorFlows] = useState<Flow[]>([])
  const [currentTodoIndex, setCurrentTodoIndex] = useState(0)

  useSubscription(FLOW_SUBSCRIPTION, {
    variables: { key: flow?.id },
    skip: !flow,
    onData: () => {
      fetchFlow(flow?.id)
    },
    onError: (error: ApolloError) => {
      console.log(error.message)
    },
  })
  const [getFlowAndBlocks, { loading }] = useLazyQuery(GET_FLOW)
  const [getFlowWithDecendants] = useLazyQuery(GET_FLOW_WITH_DESCENDANTS)
  const [mutateUpdateFlow] = useMutation(UPDATE_FLOW, {
    onCompleted: ({ updateFlow: { error, flow } }) => {
      if (error) {
        console.log(error)
      }
      setFlow(flow)
    },
    onError: (error: ApolloError) => {
      console.log(error.message)
    },
  })

  const fetchFlow = async (id) => {
    setAncestorFlows([])
    const {
      data: {
        flowById: { flow },
      },
    } = await getFlowAndBlocks({
      variables: { id },
      onCompleted: async ({ flowById: { flow, error } }) => {
        if (error) {
          throw new Error(`Internal Error fetching flow blocks: ${error}`)
        }
        setFlow(flow)
        if (flow.parentId) {
          const ancestors = await fetchAncestorFlows(flow.parentId)
          setAncestorFlows(ancestors)
        }
      },
      onError: (error) => {
        throw new Error(`Error fetching flow blocks: ${error}`)
      },
    })
    return flow
  }

  const fetchAncestorFlows = async (flowId) => {
    const {
      data: {
        flowById: { flow, error: flowError },
      },
      error,
    } = await getFlowAndBlocks({ variables: { id: flowId } })
    if (error) {
      console.log('Error fetching flow blocks: ', error)
      return []
    }
    if (flowError) {
      console.log('Internal Error fetching flow blocks: ', flowError)
      return []
    }
    if (flow?.parentId === null) return [flow]

    return [flow, ...(await fetchAncestorFlows(flow.parentId))]
  }

  // Fetches all descendant flows of a given root flow id.
  const fetchDescendantFlows = async (rootFlowId) => {
    const queue = [rootFlowId]
    const visited = new Set<string>()
    const flows: Flow[] = []

    while (queue.length > 0) {
      const id = queue.shift()
      if (id && !visited.has(id)) {
        visited.add(id)
        const {
          data: {
            flowById: { flow, error: flowError },
          },
          error,
        } = await getFlowWithDecendants({ variables: { id } })

        if (error) {
          console.log('Error fetching flow blocks: ', error)
          continue
        }
        if (flowError) {
          console.log('Internal Error fetching flow blocks: ', flowError)
          continue
        }

        if (flow) {
          flows.push(flow)
          flow.children.forEach((child) => queue.push(child.id))
        }
      }
    }
    return flows
  }

  const updateFlow = async (input: FlowUpdateInput) => {
    try {
      const {
        data: {
          updateFlow: { flow },
        },
      } = await mutateUpdateFlow({ variables: { input: { ...input } } })
      return flow
    } catch (error) {
      throw new Error(error)
    }
  }

  const todoPaginationStep = (step) => {
    const next = currentTodoIndex + step
    if (todoFlows && (next < 0 || next > todoFlows?.length - 1)) return
    setCurrentTodoIndex(next)
    navigate(`${pathPrefix.flows.single}${todoFlows[next].id}`, { replace: true })
  }

  return {
    loading,
    flow,
    ancestorFlows,
    todoFlows,
    currentTodoIndex,
    setCurrentTodoIndex,
    todoPaginationStep,
    fetchFlow,
    updateFlow,
    fetchAncestorFlows,
    fetchDescendantFlows,
  }
}
