/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { client as apollo } from 'helpers/apollo'
import { AppDispatch, RootState } from 'helpers/store'
import { useDispatch, useSelector } from 'react-redux'
import { GET_PROJECTS, GET_NODE, CREATE_NODE, UPDATE_NODE, DUPLICATE_NODE, DELETE_NODE } from '../node/node.schema'
import { Node } from 'types/graphqlSchema'

// ---------------------------------------
// Thunks
// ---------------------------------------

const getProjects = createAsyncThunk('project/getProjects', async (params: any) => {
  try {
    const { data, errors } = await apollo.query({
      query: GET_PROJECTS,
      variables: {
        ...params,
      },
    })
    if (data) return data.myActiveProjects
    if (errors) throw new Error(errors[0].message)
  } catch (error) {
    throw new Error(error)
  }
})

const getProject = createAsyncThunk('project/getProject', async (projectId: string | undefined) => {
  try {
    const {
      data: { node },
    } = await apollo.query({
      query: GET_NODE,
      variables: {
        id: projectId,
      },
    })
    return node
  } catch (error: any) {
    throw new Error(error)
  }
})

const createProject = createAsyncThunk('project/createProject', async (params: any) => {
  try {
    const {
      data: { createNode },
    } = await apollo.mutate({
      mutation: CREATE_NODE,
      variables: {
        input: { ...params },
      },
    })
    return createNode
  } catch (error) {
    return error
  }
})

const updateProject = createAsyncThunk('box/updateProject', async (params: any) => {
  try {
    const {
      data: { updateNode },
    } = await apollo.mutate({
      mutation: UPDATE_NODE,
      variables: {
        input: { ...params },
      },
    })
    return updateNode
  } catch (error: any) {
    throw new Error(error)
  }
})

const deleteProject = createAsyncThunk('project/deleteProject', async (id: string) => {
  try {
    const { data } = await apollo.mutate({
      mutation: DELETE_NODE,
      variables: { id },
    })
    return data.deleteNode
  } catch (error: any) {
    throw new Error(error)
  }
})

const duplicateProject = createAsyncThunk('project/duplicateProject', async (id: string) => {
  try {
    const {
      data: { duplicateNode },
    } = await apollo.mutate({
      mutation: DUPLICATE_NODE,
      variables: { id },
    })
    return duplicateNode
  } catch (error: any) {
    throw new Error(error)
  }
})

interface ProjectState {
  currentProject?: Node
  projects: Node[]
  pendingInfo: {
    getProject: string | undefined
  }
  error: any
}

const initialState: ProjectState = {
  currentProject: undefined,
  projects: [],
  pendingInfo: { getProject: undefined },
  error: null,
}

// slice
const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    clearProjects(state) {
      state.projects = []
    },
    clearCurrentProject(state) {
      state.currentProject = undefined
    },
    updateProjectInfo(state, { payload }) {
      if (!payload) return
      if (state.currentProject?.id === payload.id) {
        state.currentProject = payload
      }
      state.projects = state.projects.map((project) => {
        if (project.id === payload.id) {
          return payload
        }
        return project
      })
    },
    setCurrentProject(state, { payload }) {
      state.currentProject = payload
    },
    setProjects(state, { payload }) {
      state.projects = payload
    },
    removeProject(state, { payload }) {
      state.projects = state.projects.filter((project) => project.id !== payload.id)
    },
    removeProjectChildren(state, { payload }) {
      if (!state.currentProject?.children) return
      state.currentProject.children = state.currentProject.children.filter((node: Node) => node.id !== payload.nodeId)
    },
    updateSharedMembers(state, { payload }) {
      if (!payload) return

      if (state.currentProject) {
        if (payload.type === 'PROJECT') {
          state.currentProject.sharedMembers = payload.sharedMembers
        }
      }
      state.projects = state.projects.map((project) => {
        if (project.id === payload.id) {
          project.sharedMembers = payload.sharedMembers
        }
        return project
      })
    },
    updateProjectLinkShare(state, { payload }) {
      if (!payload) return
      if (payload.nodeType === 'PROJECT') {
        if (state.currentProject && payload.nodeId === state.currentProject.id) {
          state.currentProject.linkShare = payload
        }
        state.projects = state.projects.map((project) => {
          if (payload.nodeId === project.id) {
            project.linkShare = payload
          }
          return project
        })
      }
      if (state.currentProject?.children) {
        state.currentProject.children = state.currentProject.children.map((node: any) => {
          if (node.id === payload.nodeId) {
            node.linkShare = payload
          }
          return node
        })
      }
    },
    removeProjectLinkShare(state, { payload }) {
      if (!payload) return
      if (payload.nodeType === 'PROJECT') {
        if (state.currentProject && payload.nodeId === state.currentProject.id) {
          state.currentProject.linkShare = null
        }
        state.projects = state.projects.map((project) => {
          if (payload.nodeId === project.id) {
            project.linkShare = undefined
          }
          return project
        })
      }
      if (!state.currentProject?.children) return

      state.currentProject.children = state.currentProject.children.map((node: any) => {
        if (node.id === payload.nodeId) {
          node.linkShare = undefined
        }
        return node
      })
    },
    addNewChildtoProjectBox(state, { payload }) {
      if (!payload) return
      if (!state.currentProject?.children) return
      state.currentProject.children = state.currentProject.children.map((node: Node) => {
        if (payload.parent === node.id && node.children) {
          node.children = [...node.children, payload.moved]
        }
        return node
      })
      state.currentProject.children = state.currentProject.children.filter((node: Node) => node.id !== payload.moved.id)
    },
    clearProjectPendingInfo(state) {
      state.pendingInfo.getProject = undefined
    },
  },
  extraReducers: (builder) => {
    // GET_PROJECTS
    builder.addCase(getProjects.pending, (state) => {
      state.error = null
    })
    builder.addCase(getProjects.fulfilled, (state, { payload }: any) => {
      if (!payload) return
      state.projects = payload.filter((project: IProject) => project !== null)
    })
    builder.addCase(getProjects.rejected, (state, { error }) => {
      state.error = error
    })
    // GET PROJECT
    builder.addCase(getProject.pending, (state, { meta }) => {
      const { requestId } = meta
      state.pendingInfo = {
        getProject: requestId,
      }
      state.error = null
    })
    builder.addCase(getProject.fulfilled, (state, { payload }: any) => {
      if (!payload) return
      const clone = { ...payload }

      clone.children = payload.children.filter(Boolean)
      state.currentProject = clone
    })
    builder.addCase(getProject.rejected, (state, { error }) => {
      state.error = error.message
      state.currentProject = undefined
    })
    // CREATE PROJECT
    builder.addCase(createProject.fulfilled, (state, { payload }: any) => {
      // if (payload?.coverImage) {
      //   payload.coverImage = 'coverImage'
      // }
      state.projects.unshift(payload)
    })
    builder.addCase(createProject.rejected, (state, { payload }) => {
      state.error = payload
    })
    // Duplicate Project
    builder.addCase(duplicateProject.fulfilled, (state, { payload }) => {
      const existingProjects = state.projects
      existingProjects.unshift(payload)
      state.projects = existingProjects
    })
    // UPDATE PROJECT
    builder.addCase(updateProject.fulfilled, (state, { payload }: any) => {
      state.projects = state.projects.map((project) => {
        if (payload.id === project.id) {
          return payload
        }
        return project
      })
      if (state.currentProject && state.currentProject.id === payload.id) {
        state.currentProject = payload
      }
    })
    builder.addCase(updateProject.rejected, (state, { payload }) => {
      state.error = payload
    })
    // DELETE PROJECT
    builder.addCase(deleteProject.fulfilled, (state, { payload }: any) => {
      state.projects = state.projects.filter((project) => {
        if (payload === project.id) {
          return false
        }
        return project
      })
    })
  },
})

export function useProjectSlice() {
  const dispatch = useDispatch<AppDispatch>()
  const state = useSelector((s: RootState) => s.project)
  return {
    dispatch,
    ...state,
    getProjects,
    getProject,
    createProject,
    duplicateProject,
    updateProject,
    deleteProject,
    ...projectSlice.actions,
  }
}

export default projectSlice.reducer
