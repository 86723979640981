import { useState, useRef } from 'react'
import {
  Flex,
  Text,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Icon,
  useOutsideClick,
} from '@chakra-ui/react'
import { useAppSlice } from 'features/redux'
import { icons } from 'theme'
import { FlowSortDefault, ProjectSortDefault } from 'features/app'

interface User {
  name: string
  id: string
  avatar?: string
}

interface SortPopoverProps {
  currentSortType: string
  sortOptions: string[]
  setDescending: (boolean) => void
  onOpen: () => void
  users?: User[]
  selectedUserId?: string
}

const SortPopover = ({
  setDescending,
  onOpen,
  users,
  currentSortType,
  sortOptions,
  selectedUserId,
}: SortPopoverProps) => {
  const { dispatch, sortType, setSortType } = useAppSlice()
  const [open, setOpen] = useState(false)
  const ref = useRef<HTMLInputElement>(null)
  useOutsideClick({
    ref,
    handler: () => setOpen(false),
  })

  function getUserForFilter(userId) {
    const person = users?.find((obj) => obj.id === userId)
    const nameForSpecificId = person ? person.name : null
    return nameForSpecificId
  }

  return (
    <Flex fontSize={14}>
      <Text alignSelf="center" mr={2}>
        Sort By
      </Text>
      <Popover isLazy isOpen={open} closeOnBlur closeOnEsc>
        <PopoverTrigger>
          <Button
            variant="unstyled"
            display="flex"
            gap={2}
            // need to fix
            _focusVisible={{ boxShadow: '0 0 0 1px var(--chakra-colors-borderFocus)' }}
            alignItems="center"
            fontSize={14}
            onClick={() => setOpen(true)}
          >
            <Text color="textHighlight">{currentSortType}</Text>
          </Button>
        </PopoverTrigger>

        {/* Descending Toggle */}
        {currentSortType !== 'Sender' && currentSortType !== 'Owner' && (
          <Button
            variant="unstyled"
            display="flex"
            ml={-1}
            alignItems={'center'}
            fontSize={14}
            _focusVisible={{ boxShadow: '0 0 0 1px var(--chakra-colors-borderFocus)' }}
            onClick={() => {
              setDescending((prev) => !prev)
              setOpen(false)
            }}
          >
            <Icon as={icons.sortIcon} color="textRegular" fontSize={18} />
          </Button>
        )}

        {(currentSortType === 'Sender' || currentSortType === 'Owner') && (
          <Text alignSelf={'center'} ml={2}>
            {getUserForFilter(selectedUserId)}
          </Text>
        )}

        <PopoverContent border={'none'} w={'204px'} overflowY="auto" bg="textHighlight">
          <PopoverBody p={0} ref={ref}>
            {/* <FocusLock> */}
            <Flex flexDir={'column'} py={4} px={1} boxSizing="border-box" fontSize={14}>
              {sortOptions.map((option) => (
                <Button
                  key={option}
                  mx={4}
                  fontSize={'inherit'}
                  variant="transparentButton"
                  color="textBlack"
                  _hover={{ bg: 'menuBackground', color: 'textHighlight', fill: 'textHighlight' }}
                  textAlign={'left'}
                  onClick={() => {
                    setOpen(false)
                    if (option === 'Sender' || option === 'Owner') {
                      onOpen()
                    } else if (currentSortType === sortType.flow) {
                      const flowSortType = option as FlowSortDefault
                      dispatch(setSortType({ ...sortType, flow: flowSortType }))
                    } else if (currentSortType === sortType.project) {
                      const projectSortType = option as ProjectSortDefault
                      dispatch(setSortType({ ...sortType, project: projectSortType }))
                    }
                  }}
                >
                  {option}
                </Button>
              ))}
            </Flex>
            {/* </FocusLock> */}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  )
}

export default SortPopover
