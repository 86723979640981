import { mode } from '@chakra-ui/theme-tools'
import { images } from 'theme'

export const pathLocation = {
  project: 'project',
  personal: 'personal',
  flows: 'flows',
  recent: 'recent',
  following: 'following',
  trash: 'trash',
}

export const pathPrefix = {
  home: {
    project: `/${pathLocation.project}/`,
    box: `/${pathLocation.project}/`,
  },
  personal: {
    insideBox: `/${pathLocation.personal}/`,
  },
  tip: {
    review: '/file/',
  },
  flows: {
    dashboard: `/${pathLocation.flows}`,
    single: `/${pathLocation.flows}/flow/`,
  },
}

export const path = {
  auth: {
    login: '/login',
    signup: '/signup',
    sso: '/sso',
    confirmEmail: '/confirm-email',
    resetPassword: {
      index: '/reset-password',
      await: '/reset-password/await',
      verification: '/reset-password/verification',
    },
    link: {
      index: '/link',
      link: '/link/:nodeId',
    },
  },
  home: {
    index: '/',
    project: `/${pathLocation.project}/:projectId`,
    box: `/${pathLocation.project}/:projectId/*`,
  },
  personal: {
    index: `/${pathLocation.personal}`,
    insideBox: `/${pathLocation.personal}/*`,
  },
  flows: {
    flows: `/${pathLocation.flows}`,
    flowView: `/${pathPrefix.flows.single}:flowId`,
  },
  search: {
    search: '/search',
  },
}

// [read, write, delete, download, upload, share, start_flow, respond_flow]
export const permissionIndexes: { [key: string]: number } = {
  view: 0,
  edit: 1,
  delete: 2,
  download: 3,
  upload: 4,
  share: 5,
  startFlow: 6,
  respondFlow: 7,
}

export const privacySetting = {
  organizationWide: 'ORGANIZATION_WIDE',
  inviteOnly: 'INVITE_ONLY',
}

export const menuActions = {
  tag: 'tag',
  flow: 'flow',
  share: 'share',
  unshare: 'unshare',
  preview: 'preview',
  copyLink: 'copyLink',
  follow: 'follow',
  coverImage: 'coverImage',
  rename: 'rename',
  details: 'details',
  moveTo: 'moveTo',
  duplicate: 'duplicate',
  download: 'download',
  uploadTip: 'uploadTip',
  uploadBox: 'uploadBox',
  delete: 'delete',
  permanentlyDelete: 'permanentlyDelete',
  comment: 'comment',
  restore: 'restore',
  manageVersions: 'manageVersions',
}

export const flowActions = {
  approveFlow: 'approveFlow',
  rejectFlow: 'rejectFlow',
  cancelFlow: 'cancelFlow',
  deleteFlow: 'deleteFlow',
  archiveFlow: 'archiveFlow',
  removeFromFlow: 'removeFromFlow',
  submitFeedback: 'submitFeedback',
  uploadFiles: 'uploadFiles',
  removeFile: 'removeFile',
  createFlow: 'createFlow',
}

export const menuProject: { [key: string]: string[] } = {
  base: [
    'share',
    'link',
    'follow',
    'coverImage',
    'rename',
    // 'details',
    'duplicate',
    'download',
    'delete',
  ],
  follow: ['share', 'link', 'download', 'follow', 'details'],
  recent: ['share', 'link', 'download'],
}

export const menuBox: { [key: string]: string[] } = {
  base: ['share', 'link', 'coverImage', 'rename', 'moveTo', 'follow', 'details', 'duplicate', 'download', 'delete'],
  personal: ['coverImage', 'rename', 'moveTo', 'follow', 'details', 'duplicate', 'download', 'delete'],
  follow: ['share', 'link', 'download', 'follow', 'details'],
  recent: ['share', 'link', 'download'],
  publicLink: ['download'],
}

export const menuTip: { [key: string]: string[] } = {
  base: [
    'share',
    'link',
    // 'comment',
    'moveTo',
    'rename',
    'coverImage',
    'manageVersions',
    'follow',
    'details',
    'duplicate',
    'download',
    'delete',
  ],
  personal: [
    'moveTo',
    'rename',
    'coverImage',
    'manageVersions',
    'follow',
    'details',
    'duplicate',
    'download',
    'delete',
  ],
  personalDocument: ['moveTo', 'follow', 'download', 'delete'],
  contentCreator: [
    // 'share',
    'link',
    // 'comment',
    'moveTo',
    // 'rename',
    // 'coverImage',
    'manageVersions',
    'follow',
    'details',
    // 'duplicate',
    'download',
    'delete',
  ],
  follow: ['share', 'link', 'download', 'follow', 'details'],
  mobileMenuTip: ['details', 'followTip', 'moveTo', 'manageVersions', 'duplicate', 'download', 'delete'],
  recent: ['share', 'link', 'download'],
  publicLink: ['download', 'follow'],
}

export const menuListCard: { [key: string]: { [key: string]: string[] } } = {
  PROJECT: {
    follow: ['follow'],
    recent: ['download'],
    trash: ['restore', 'delete'],
    search: [
      'share',
      'link',
      'rename',
      // 'details',
      'follow',
      'download',
      'delete',
    ],
  },
  BOX: {
    follow: ['follow'],
    recent: ['download'],
    trash: ['restore', 'delete'],
    search: [
      'share',
      'link',
      'rename',
      // 'details',
      'download',
      'follow',
      'delete',
    ],
  },
  TIP: {
    follow: ['follow'],
    recent: ['download'],
    trash: ['restore', 'delete'],
    search: ['share', 'link', 'rename', 'follow', 'details', 'download', 'delete', 'follow'],
  },
}

export const sortOptions: { [key: string]: { label: string }[] } = {
  default: [{ label: 'Name' }, { label: 'Last Modified' }, { label: 'Size' }],
  trash: [{ label: 'Name' }, { label: 'Type' }, { label: 'Deleted' }],
  search: [{ label: 'Name' }, { label: 'Type' }, { label: 'Last Modified' }],
  recent: [{ label: 'Last Modified' }],
}

export const disableOptions: { [key: string]: string[] } = {
  project: ['moveTo'],
  personal: ['coverImage', 'tag', 'moveTo', 'delete', 'rename', 'link', 'follow', 'duplicate'],
}

export const listDisplayLabels: { [key: string]: string[] } = {
  recent: ['lastUpdated'],
  search: ['sort', 'type', 'size', 'lastUpdated'],
  trash: ['name', 'type'],
}

export const termsOfUse = `Welcome to Tipbox! These Terms of Service ("Terms") govern your use of the Tipbox platform, which includes our websites, mobile applications, and related services (collectively, the "Service"). By using the Service, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use the Service.

1. Account Creation and Use

1.1 Account Creation. In order to use the Service, you must create an account. You agree to provide accurate and complete information when creating your account and to update it promptly if any information changes.

1.2 Account Security. You are responsible for keeping your login credentials secure and for any activity that occurs under your account. If you become aware of any unauthorized use of your account, you must notify us immediately.

2. Acceptable Use

2.1 Prohibited Activities. You may not use the Service to engage in any of the following activities:

(a) violating any law or regulation;

(b) infringing the rights of others, including intellectual property rights and privacy rights;

(c) spreading spam, malware, or other harmful software;

(d) engaging in any activity that interferes with the operation of the Service;

(e) impersonating another person or entity; or

(f) engaging in any other activity that we determine, in our sole discretion, to be harmful to the Service or our users.

2.2 Compliance with Community Guidelines. You must comply with any community guidelines that we provide in connection with the Service.

3. Content Ownership

3.1 Your Content. You retain ownership of any content that you post or upload to the Service, including messages, files, and other materials (collectively, "User Content").

3.2 Your License. By posting or uploading User Content to the Service, you grant other users of the Service a non-exclusive, non-transferable, non-sublicensable, revocable, limited license to access, view, use, and display such User Content as permitted by these Terms and the functionality of the Service.
You do not grant Tipbox any license or other right to use, copy, modify, create derivative works based on, distribute, publicly display, publicly perform, or otherwise exploit in any manner your User Content. You represent and warrant that you have all rights necessary to grant the licenses and permissions set forth in this section, and that your User Content does not infringe or violate the rights of any third party.

3.3 Feedback. If you provide us with any feedback or suggestions regarding the Service, you hereby grant us a non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use, copy, modify, create derivative works based on, distribute, publicly display, publicly perform, and otherwise exploit in any manner such feedback or suggestions in all formats and distribution channels now known or hereafter devised (including in connection with the Service and our business and on third-party sites and services), without further notice to or consent from you, and without the requirement of payment to you or any other person or entity.

4. Intellectual Property

4.1 Our Content. The Service and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof), are owned by us, our licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.

4.2 Trademarks. Our name, logo, and all related names, logos, product

5. Film and TV Producers

5.1 Use of Service. Film and TV producers may use the Service to communicate with cast and crew, share production files and documents, and collaborate on projects. However, you acknowledge and agree that the Service is not designed or intended for use in connection with the distribution, exhibition, or exploitation of films, television shows, or other audiovisual content.

5.2 Content Responsibility. You are solely responsible for any content that you upload or share through the Service, including any audiovisual content. You represent and warrant that you have all necessary rights, licenses, and permissions to upload and share such content, and that the use of such content does not infringe or violate the rights of any third party.

5.3 Release Forms. You acknowledge and agree that the Service does not provide legal advice or assistance, and that you are solely responsible for obtaining any necessary release forms, clearances, or other permissions related to the use of any audiovisual content that you upload or share through the Service.

5.4 Disclaimers. You acknowledge and agree that Tipbox does not endorse or guarantee the accuracy, reliability, or legality of any content uploaded or shared through the Service, and that Tipbox is not responsible for any damages or losses arising from the use of such content. You also acknowledge and agree that Tipbox is not responsible for any disputes or issues that may arise between you and any other users of the Service, including cast and crew members.

5.5 Indemnification. You agree to indemnify, defend, and hold harmless Tipbox and its affiliates, officers, directors, employees, agents, and licensors from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising from or related to your use of the Service, including any content that you upload or share through the Service.
`

export const emptyCard: IEmptyPanal = {
  projects: {
    subHeading: 'Hello there!',
    text: 'Let’s get you set up quickly and easily.<br> To create a Project and add content to it,<br> please',
    linkBtnText: 'click here.',
    icon: images.emptyProjects,
  },
  private: {
    subHeading: 'You have no private items',
    text: 'When you create a project, they will show up here.',
  },
  shared: {
    subHeading: 'You have no shared items',
    text: 'When you share a project, they will show up here.',
  },
  // Deprecated
  // personal: {
  //   subHeading: '',
  //   text: 'Use this space when you want to add content that is not tied to any project. You can still share and move items to a project later if needed.',
  //   linkBtnText: 'Browse',
  //   text2: 'or drag and drop to upload.',
  //   icon: images.emptyPersonal,
  // },
  // Deprecated - Flows Page Intro
  // flows: {
  //   subHeading: 'Go with the flow!',
  //   text: 'Easily get approvals, feedback, and more by creating Flows. Start a Flow on an item and come back here to quickly view, track and manage it all.',
  //   icon: images.flowsBoltRed,
  // },
  recent: {
    subHeading: 'Time is money',
    text: 'Once you have opened some items, come back<br> here to find them again easily.',
    icon: images.emptyRecent,
  },
  following: {
    subHeading: 'Follow your dreams',
    text: '... and of course, other important content. Quickly scan all items that are meaningful to you here.',
    icon: images.emptyFollowing,
  },
  project: {
    subHeading: 'Let’s add some goods',
    text: 'Drag files / folders here or use the "New" button.<br>',
    linkBtnText: 'Click here',
    text2: 'to browse items from your device.',
    icon: images.emptyProject,
  },
  insideBox: {
    subHeading: 'Let’s add some goods',
    text: 'Drag files / folders here or use the "New" button.<br>',
    linkBtnText: 'Click here',
    text2: 'to browse items from your device.',
    icon: images.emptyProject,
  },
  trash: {
    subHeading: 'Nothing to see here...',
    text: 'When you delete an item, they will show up here.<br> Items in the trash are deleted after 30 days.',
    icon: images.trashEmptyState,
  },
  search: {
    subHeading: 'Sorry, we could not find what you are looking for.',
    text: 'Please try using a different search keyword.',
    icon: images.emptyProject,
  },
  LinkShareBox: {
    subHeading: 'There have no items',
    text: '',
    icon: images.emptyProject,
  },
}

export const updateValues: IUpdateModalDefaults = {
  rename: {
    header: 'Rename',
    placeholder: 'Enter a new folder name',
    label: 'New name',
  },
  coverImage: {
    header: 'Add cover photo',
    placeholder: 'Choose a photo',
    label: '',
    isImage: true,
  },
}
export const tipUpdateValues: IUpdateModalDefaults = {
  rename: {
    header: 'Rename',
    placeholder: 'Enter a new tip name',
    label: 'New name',
  },
  coverImage: {
    header: 'Add cover photo',
    placeholder: 'Choose a photo',
    label: '',
    isImage: true,
  },
}

export const docExts = ['doc', 'docx', 'html', 'htm', 'odt', 'ods', 'txt']
export const excelExts = ['xls', 'xlsx', 'csv']
export const pptExts = ['ppt', 'pptx']
export const imageExts = ['ai', 'bmp', 'gif', 'ico', 'jpeg', 'jpg', 'png', 'ps', 'psd', 'svg', 'tif', 'tiff']
export const audioExts = ['aif', 'cda', 'mid', 'midi', 'mp3', 'mpa', 'm4a', 'ogg', 'wav', 'wma', 'wpl']
export const videoExts = [
  'webm',
  'mpg',
  'mp2',
  'mpeg',
  'mpe',
  'mpv',
  'mp4',
  'm4p',
  'm4v',
  'avi',
  'wmv',
  'mov',
  'qt',
  'flv',
  'swf',
  'avchd',
  '3g2',
  '3gp',
  'h264',
  'mkv',
  'rm',
  'swf',
  'vob',
]

/**
 * extension types
 * https://www.figma.com/file/ZiCL3WFDUnMmhgWnB630XV/Untitled?node-id=0%3A1&t=aVy6fXyDEqWARiBJ-0
 */
export const extensionIcons: { src: string; exts: string[] }[] = [
  {
    src: images.fileDoc,
    exts: ['doc', 'docm', 'docx', 'dot', 'dotx'],
  },
  {
    src: images.filePot,
    exts: ['pot', 'potm', 'potx', 'ppam', 'pps', 'ppsm', 'ppsx', 'ppt', 'pptm', 'pptx'],
  },
  {
    src: images.fileOne,
    exts: ['one', 'onepkg', 'onetoc', 'onetoc2'],
  },
  {
    src: images.fileXlsx,
    exts: ['xlsx', 'xlsm', 'xlsb', 'xltx', 'xltm', 'xls', 'xlt', 'xml', 'xlam', 'xla', 'xlw', 'xlr'],
  },
  {
    src: images.filePdf,
    exts: ['pdf', 'pdf/a', 'pdf/e', 'pdf/x', 'pdf/vt', 'pdf/ua'],
  },
  {
    src: images.fileEps,
    exts: ['eps'],
  },
  {
    src: images.filePs,
    exts: ['psd'],
  },
  {
    src: images.fileInd,
    exts: ['ind', 'indd', 'indt'],
  },
  {
    src: images.fileAi,
    exts: ['ai'],
  },
  {
    src: images.fileSvg,
    exts: ['svg', 'svgz'],
  },
  {
    src: images.fileBmp,
    exts: ['bmp'],
  },
  {
    src: images.fileRaw,
    exts: ['raw', 'cr2', 'erf', 'raf', 'rw2', 'nrw', 'nef', 'arw', 'eip', 'rwz', 'dng', 'gpr', 'srf'],
  },
  {
    src: images.fileWav,
    exts: ['pcm', 'wav', 'aiff', 'mp3', 'aac', 'ogg', 'wma', 'flac', 'alac', 'wma'],
  },
  {
    src: images.fileCsv,
    exts: ['csv'],
  },
  {
    src: images.fileRtf,
    exts: ['rtf'],
  },
  {
    src: images.fileTxt,
    exts: ['txt'],
  },
  {
    src: images.fileFdr,
    exts: ['fdr', 'fdx'],
  },
  {
    src: images.fileMmx,
    exts: ['mmx', 'mmsw'],
  },
  {
    src: images.fileImg,
    exts: ['gif', 'jpg', 'jpeg', 'png', 'tif', 'webp', 'heic'],
  },
  {
    src: images.fileMovie,
    exts: ['mp4', 'mov', 'mkv', 'm4v'],
  },
]

export const nodeType = {
  project: 'PROJECT',
  box: 'BOX',
  tip: 'TIP',
}

export const blockType = {
  text: 'TEXT',
  image: 'IMAGE',
  file: 'FILE',
  audio: 'AUDIO',
  video: 'VIDEO',
  embed: 'EMBED',
}

export const nodeState = {
  active: 'ACTIVE',
  inactive: 'INACTIVE',
  pending: 'PENDING',
}

export const approvalBy = {
  me: 'ME',
  meOthers: 'ME_OTHERS',
  others: 'OTHERS',
}

export const userApprovalState = {
  init: 'Init',
  approved: 'Approved',
  pending: 'Pending',
  rejected: 'Rejected',
  viewed: 'Viewed',
}

export const selectedStyle = {
  color: mode('tipbox.primary.600', 'accent'),
  borderBottom: '2px',
  borderColor: mode('tipbox.primary.600', 'accent'),
}

export const imageSizes = {
  web: {
    project: {
      banner: {
        width: 1116,
        height: 756,
      },
      main: {
        width: 256,
        height: 160,
      },
      recents_follow: {
        width: 240,
        height: 144,
      },
      list_view: {
        width: 64,
        height: 64,
      },
      optional: {
        width: 48,
        height: 48,
      },
    },
    box: {
      main: {
        width: 444,
        height: 306,
      },
      grid_view: {
        width: 186,
        height: 126,
      },
      list_view: {
        width: 64,
        height: 64,
      },
      optional: {
        width: 40,
        height: 40,
      },
    },
    tip: {
      main: {
        width: 306,
        height: 306,
      },
      grid_view: {
        width: 84,
        height: 84,
      },
      list_view: {
        width: 64,
        height: 64,
      },
      optional: {
        width: 40,
        height: 40,
      },
    },
    profile: {
      main: {
        width: 48,
        height: 48,
      },
      account: {
        width: 130,
        height: 130,
      },
      share_window: {
        width: 32,
        height: 32,
      },
    },
  },
  mobile: {
    project: {
      banner: {
        width: 340,
        height: 204,
      },
      main: {
        width: 161,
        height: 96.6,
      },
      recents_follow: {
        width: 309,
        height: 200,
      },
      list_view: {
        width: 110,
        height: 66,
      },
      optional: {
        width: 48,
        height: 48,
      },
    },
    box: {
      main: {
        width: 104,
        height: 71.57,
      },
      list_view: {
        width: 64,
        height: 64,
      },
    },
    tip: {
      main: {
        width: 54,
        height: 54,
      },
      list_view: {
        width: 54,
        height: 54,
      },
    },
    profile: {
      main: {
        width: 48,
        height: 48,
      },
      account: {
        width: 96,
        height: 96,
      },
    },
  },
}

export const flowsGroups = {
  todo: 'TODO',
  inProgress: 'IN_PROGRESS',
  inTheLoop: 'IN_THE_LOOP',
  completed: 'COMPLETED',
}

export const workflowConstant = {
  approval: {
    name: 'Approval',
    state: {
      pending: 'Pending',
      approved: 'Approved',
      rejected: 'Rejected',
      cancelled: 'Cancelled',
      unresolved: 'Unresolved',
      archived: 'Archived',
    },
    transition: {
      sendForApproval: 'Send for Approval',
      approve: 'Approve',
      reject: 'Reject',
      cancel: 'Cancel',
      removeMe: 'Remove Me',
      archive: 'Archive',
      unresolve: 'Unresolve',
    },
  },
  feedback: {
    name: 'Feedback',
    state: {
      pending: 'Pending',
      cancelled: 'Cancelled',
      archived: 'Archived',
      submitted: 'Submitted',
      unresolved: 'Unresolved',
    },
    transition: {
      sendForApproval: 'Send for Feedback',
      cancel: 'Cancel',
      removeMe: 'Remove Me',
      archive: 'Archive',
      submit: 'Submit',
      unresolve: 'Unresolve',
    },
  },
  signature: {
    name: 'Signature',
  },
}

export const NOTIFICATION_CHANNEL = {
  EMAIL: 'EMAIL',
  SMS: 'SMS',
  PUSH: 'PUSH',
  SLACK: 'SLACK',
  MS_TEAMS: 'MS_TEAMS',
}

export const EVENT_TYPE = {
  FLOW_ACTIVITY: 'FLOW_ACTIVITY',
  FLOW_SENT: 'FLOW_SENT',
  FLOW_USER_REMOVED: 'FLOW_USER_REMOVED',
  FLOW_CANCELLED: 'FLOW_CANCELLED',
  FLOW_APPROVED: 'FLOW_APPROVED',
  FEEDBACK_FLOW_SUBMITTED: 'FEEDBACK_FLOW_SUBMITTED',
  FLOW_REJECTED: 'FLOW_REJECTED',
  FLOW_UNRESOLVED: 'FLOW_UNRESOLVED',
  FLOW_ARCHIVED: 'FLOW_ARCHIVED',
  FLOW_USER_MENTIONED: 'FLOW_USER_MENTIONED',
  FLOW_INTERVAL_REMINDER_CREATOR: 'FLOW_INTERVAL_REMINDER_CREATOR',
  FLOW_INTERVAL_REMINDER_ASSIGNEE: 'FLOW_INTERVAL_REMINDER_ASSIGNEE',
  // FLOW_TODAY_DUE_REMINDER_CREATOR: 'FLOW_TODAY_DUE_REMINDER_CREATOR',
  // FLOW_TODAY_DUE_REMINDER_ASSIGNEE: 'FLOW_TODAY_DUE_REMINDER_ASSIGNEE',
  // FLOW_PAST_DUE_REMINDER_CREATOR: 'FLOW_PAST_DUE_REMINDER_CREATOR',
  // FLOW_PAST_DUE_REMINDER_ASSIGNEE: 'FLOW_PAST_DUE_REMINDER_ASSIGNEE',
  USER_MENTIONED: 'USER_MENTIONED',
  RESOURCE_FOLLOWED: 'RESOURCE_FOLLOWED',
  RESOURCE_SHARED: 'RESOURCE_SHARED',
  IN_THE_LOOP: 'IN_THE_LOOP',
  EMAIL_VERIFICATION: 'EMAIL_VERIFICATION',
  SMS_VERIFICATION: 'SMS_VERIFICATION',
  SLACK_VERIFICATION: 'SLACK_VERIFICATION',
}

export const MESSAGE_TYPE = {
  CHAT: 'CHAT',
  COMMENT: 'COMMENT',
  NOTE: 'NOTE',
}

export const TRACKING_EVENT_TYPE = {
  VIEW_PROJECT: 'VIEW_PROJECT',
}
