import React, { useState, Dispatch, useEffect } from 'react'
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Spacer,
  Image,
  Avatar,
  Text,
  Button,
  useBreakpointValue,
  useColorModeValue,
  Divider,
  Icon,
  Center,
  Progress,
  useDisclosure,
} from '@chakra-ui/react'
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom'
import { find } from 'lodash'
import { AlertWithAction, IconButton, Toast } from 'components'
import { customIcons, icons, images } from 'theme'
import { getResizedImage } from 'helpers/storage'
import { useAppSlice } from 'features/app'
import { PrivacySetting, UpdateOrganizationInput } from 'types/graphqlSchema'

export interface AdminContext {
  privacyValue: PrivacySetting
  setPrivacyValue: Dispatch<React.SetStateAction<PrivacySetting>>
  orgNameFromDB: string
  orgLogoFromDB: string
  orgNameValue: string
  setOrgNameValue: Dispatch<React.SetStateAction<string>>
}

const AdminLayout = () => {
  const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure()
  const [navigateValue, setNavigateValue] = useState<string>('')
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const {
    dispatch,
    updateMe,
    updateOrganization,
    user: {
      firstName,
      lastName,
      avatarSecureUrl,
      organization,
      organizationId,
      organization: { privacySetting, name: orgNameFromDB, logoSecureUrl },
    },
  } = useAppSlice()
  const [hovering, setHovering] = useState('')
  const adminMenus = [
    {
      name: 'General',
      subtitle: 'General Settings',
      icon: customIcons.gearIcon,
      path: '/admin',
    },
    {
      name: 'Privacy & Auth.',
      subtitle: 'Privacy & Authentication Settings',
      icon: customIcons.privacyAuthIcon,
      path: '/admin/privacy-auth',
    },
    {
      name: 'User Management',
      subtitle: 'User Managerment Settings',
      icon: customIcons.userManagementIcon,
      path: '/admin/user-management',
    },
    {
      name: 'Projects',
      subtitle: 'Projects Management Settings',
      icon: customIcons.projectIcon,
      path: '/admin/projects',
    },
    {
      name: 'Billing',
      subtitle: 'Organization Billing Settings',
      icon: customIcons.creditCardIcon,
      path: '/admin/billing',
    },
    {
      name: 'Data Storage',
      subtitle: 'Organization Data Storage Settings',
      icon: customIcons.dataStorageIcon,
      path: '/admin/data-storage',
    },
  ]

  const [privacyValue, setPrivacyValue] = useState<PrivacySetting>(privacySetting)
  const [orgNameValue, setOrgNameValue] = useState<string>(orgNameFromDB)
  const [changedValues, setChangedValues] = useState<string[]>([])

  const handleSaveChanges = async () => {
    if (changedValues.length >= 1) {
      try {
        const organizationInput: UpdateOrganizationInput = {
          id: organizationId,
          name: orgNameValue,
          privacySetting: privacyValue,
        }
        const res = await dispatch(updateOrganization(organizationInput))
        if (res.meta.requestStatus === 'fulfilled') {
          const newPrivacySetting =
            res.payload.privacySetting === privacySetting
              ? null
              : res.payload.privacySetting === 'INVITE_ONLY'
              ? 'Invite-only'
              : 'Public'
          const newName = res.payload.name === orgNameFromDB ? null : res.payload.name
          newPrivacySetting &&
            Toast.show({
              icon: 'check',
              message: 'Default privacy setting updated to ' + newPrivacySetting + '.',
            })
          newName &&
            Toast.show({
              icon: 'check',
              message: 'Your organization has been renamed "' + newName + '".',
            })
          // get values into in redux user object ⬇️
          dispatch(updateMe({}))
        }
      } catch (error) {
        console.error('Error updating organization:', error)
      }
    }
  }

  const handleBackButton = () => {
    if (changedValues.length < 1) {
      navigate(-1)
    } else {
      setNavigateValue('back')
      onOpenAlert()
    }
  }

  const resetValues = () => {
    onCloseAlert()
    setPrivacyValue(privacySetting)
    setOrgNameValue(orgNameFromDB)
  }

  useEffect(() => {
    // look for changed values
    // check whether to prompt a save on navigation
    // also enables/disables the Save Changes button
    const newChangedValues = Object.keys({
      privacyValue: privacyValue !== privacySetting,
      orgNameValue: orgNameValue !== orgNameFromDB,
    }).filter((key) => {
      return {
        privacyValue: privacyValue !== privacySetting,
        orgNameValue: orgNameValue !== orgNameFromDB,
      }[key]
    })
    setChangedValues(newChangedValues)
  }, [privacyValue, privacySetting, orgNameValue, orgNameFromDB])

  return (
    <>
      <Grid
        flexDir="column"
        h="full"
        w="full"
        minH="99.8vh"
        bg="background"
        templateAreas={`"header header"
                      "nav subheader"
                      "nav main"
                      "nav footer"`}
        gridTemplateRows={'min-content 100px 1fr 75px'}
        gridTemplateColumns={'min-content 1fr'}
      >
        {/* Admin main header */}
        <GridItem area={'header'}>
          <Flex alignItems="center" px={6} py={2}>
            <Flex alignItems="center" onClick={handleBackButton} style={{ cursor: 'pointer' }} w="80px">
              <IconButton
                variant="iconBtnSm"
                onClick={() => {}}
                ariaLabel="go back"
                icon={icons.backArrow}
                style={{
                  color: 'accentIcon',
                }}
              />
              <Heading textAlign="center" color="textRegular">
                Back
              </Heading>
            </Flex>
            <Spacer />
            <Flex alignItems="center">
              <Link to="/dashboard" className="homeLink">
                <Image src={useColorModeValue(images.Logo, images.LogoDark)} />
              </Link>
              <Box cursor="pointer" mx="1rem" justifyContent={'center'} pt={2}>
                <Icon as={icons.outlineQuestionMark} boxSize={7} color="textRegular" />
              </Box>
              <Avatar
                name={avatarSecureUrl ? '' : `${firstName} ${lastName}`}
                size={'sm'}
                src={avatarSecureUrl ? getResizedImage(avatarSecureUrl, 'profile.main') : ''}
              />
            </Flex>
          </Flex>
        </GridItem>
        {/* Left Nav */}
        <GridItem area={'nav'} bg="menuBackground" mr={3} pt={4}>
          <Flex direction="column" h="full" px={2}>
            <Box px={4}>
              <Text variant="smallBold" color="textRegular" lineHeight="32px">
                {organization.name}
              </Text>
              <Text variant="largeHighlightBold">Administration</Text>
            </Box>
            <Box py={7}>
              <Divider borderColor="borderRegular" />
            </Box>
            <Flex as="nav" justify="flex-start" flexDir="column" h="full">
              {adminMenus.map((menu) => (
                <Flex
                  key={menu.path}
                  height="50px"
                  onMouseEnter={() => setHovering(menu.name)}
                  onMouseLeave={() => setHovering('')}
                >
                  <Center>
                    <Divider
                      visibility={pathname === menu.path ? 'visible' : 'hidden'}
                      orientation="vertical"
                      borderColor="accentIcon"
                      borderWidth="1px"
                      height="20px"
                    />
                  </Center>
                  <IconButton
                    ariaLabel={menu.name}
                    onClick={() => {
                      if (changedValues.length < 1) {
                        navigate(menu.path, { replace: true })
                      } else {
                        setNavigateValue(menu.path)
                        onOpenAlert()
                      }
                    }}
                    icon={menu.icon}
                    iconSize={{ width: 5, height: 5 }}
                    iconColor={pathname === menu.path || hovering === menu.name ? 'accentIcon' : undefined}
                    iconMarginRight={3}
                    label={menu.name}
                    variant={pathname === menu.path ? 'listButtonSelect' : 'listButton'}
                  />
                </Flex>
              ))}
            </Flex>
            <Spacer />
            <Box px={2} py={4}>
              <Flex alignItems={'center'}>
                <Text variant="tiny" color="textRegular">
                  1.8 MB of 10.0 GB used
                </Text>
                <Spacer />
                <IconButton
                  icon={icons.externalLink}
                  iconSize={{ width: 4, height: 4 }}
                  iconColor="textRegular"
                  onClick={() => {}}
                  ariaLabel="Data Storage"
                  flexDir={['row']}
                />
              </Flex>
              <Progress bgColor="textRegular" size="xs" value={18} />
            </Box>
          </Flex>
        </GridItem>
        {/* Subheader */}
        <GridItem area={'subheader'} px={isMobile ? 0 : 6} bg="menuBackground">
          <Flex alignItems={'center'}>
            <Text variant="largeHighlightBold">{find(adminMenus, ['path', pathname])?.subtitle}</Text>
            <Spacer />
            <Button
              variant="defaultButton"
              isDisabled={changedValues.length < 1}
              px={6}
              mt={6}
              onClick={handleSaveChanges}
            >
              Save Changes
            </Button>
          </Flex>
        </GridItem>
        {/* Main Content */}
        <GridItem area={'main'} w="full" px={isMobile ? 0 : 6} bg="menuBackground">
          <Outlet
            context={
              {
                privacyValue,
                setPrivacyValue,
                orgNameValue,
                setOrgNameValue,
                orgNameFromDB,
                orgLogoFromDB: logoSecureUrl,
              } satisfies AdminContext
            }
          />
        </GridItem>
        {/* Footer */}
        <GridItem area={'footer'} bg="menuBackground"></GridItem>
      </Grid>
      <AlertWithAction
        action={() => {
          handleSaveChanges()
          onCloseAlert()
          if (navigateValue === 'back') {
            navigate(-1)
          } else {
            resetValues()
            navigate(navigateValue, { replace: true })
          }
        }}
        cancelAction={() => {
          if (navigateValue === 'back') {
            navigate(-1)
          } else {
            resetValues()
            navigate(navigateValue, { replace: true })
          }
        }}
        actionText="You have unsaved changes"
        warningText={['Would you like to save the changes you made?']}
        confirmButtonText="Save Changes"
        cancelButtonText="No"
        isOpen={isOpenAlert}
        onClose={onCloseAlert}
      />
    </>
  )
}

export default AdminLayout
