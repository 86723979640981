import { useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  CREATE_FLOW,
  START_WORKFLOW,
  CANCEL_FLOW,
  ARCHIVE_FLOW,
  DELETE_FLOW,
  REMOVE_ME_FROM_FLOW,
  EXECUTE_TRANSITION,
  CREATE_BLOCK,
  DELETE_BLOCK,
  GET_FLOW_REMINDERS,
  SET_FLOW_INTERVAL_REMINDERS,
  CANCEL_FLOW_REMINDERS_FROM_RECEIVER,
} from 'features/graphql'
import { useWorkflowSlice, useAppSlice } from 'features/redux'
import { blockType, flowActions, workflowConstant } from 'helpers/constant'
import { Toast } from 'components'
import { uploadFile } from 'helpers/storage'
import { Flow } from 'types/graphqlSchema'
import { useFlow } from '.'

type UploadFileProps = {
  [key: string]: {
    blockId: string
    progress: number
    status: string
  }
}

export default () => {
  const { dispatch, fetchFlows } = useWorkflowSlice()
  const { user } = useAppSlice()
  const { fetchFlow } = useFlow()
  const [isConfirming, setIsConfirming] = useState<boolean>(false)
  const [actionType, setActionType] = useState<string>(flowActions.cancelFlow)
  const [confirmTitle, setConfirmTitle] = useState<string>('')
  const [targetFlow, setTargetFlow] = useState<Flow | undefined>(undefined)
  const [payload, setPayload] = useState<any>()
  const [, setErrorMessage] = useState('')
  const [uploadedFiles, setUploadedFiles] = useState<UploadFileProps>({})

  // GraphQL calls
  const [getFlowReminders] = useLazyQuery(GET_FLOW_REMINDERS)
  const [mutateCreateFlow] = useMutation(CREATE_FLOW, {
    onError(error) {
      setErrorMessage(error.message)
    },
  })
  const [mutateStartFlow] = useMutation(START_WORKFLOW, {
    onCompleted(data) {
      if (data && data.startWorkflow) {
        const { flowId } = data.startWorkflow.flowLog
        fetchFlow(flowId)
      }
    },
    onError(error) {
      setErrorMessage(error.message)
    },
  })
  const [mutateExecuteTransition] = useMutation(EXECUTE_TRANSITION, {
    onError: (error) => {
      setErrorMessage(error.message)
    },
  })
  const [mutateCancelFlow] = useMutation(CANCEL_FLOW, {
    onError(error) {
      setErrorMessage(error.message)
    },
  })
  const [mutateArchiveFlow] = useMutation(ARCHIVE_FLOW, {
    onError(error) {
      setErrorMessage(error.message)
    },
  })
  const [mutateRemoveMeFromFlow] = useMutation(REMOVE_ME_FROM_FLOW, {
    onError(error) {
      setErrorMessage(error.message)
    },
  })
  const [mutateDeleteFlow] = useMutation(DELETE_FLOW)
  const [mutateAddBlock] = useMutation(CREATE_BLOCK, {
    onError(error) {
      setErrorMessage(error.message)
    },
  })
  const [mutateDeleteBlock] = useMutation(DELETE_BLOCK, {
    onError(error) {
      setErrorMessage(error.message)
    },
  })

  const [mutateSetFlowIntervalReminders] = useMutation(SET_FLOW_INTERVAL_REMINDERS, {
    onError(error) {
      setErrorMessage(error.message)
    },
  })

  const [mutateCancelFlowRemindersFromReceiver] = useMutation(CANCEL_FLOW_REMINDERS_FROM_RECEIVER, {
    onError(error) {
      setErrorMessage(error.message)
    },
  })

  const createFlow = async (input) => {
    const {
      data: {
        createFlow: { error, flow },
      },
    } = await mutateCreateFlow({ variables: { input } })
    if (error) throw new Error(error)
    if (!flow) throw new Error('Error creating a new flow')
    return flow
  }

  const startFlow = async (flowType, input) => {
    const { participantsEmails } = input
    const {
      data: {
        startWorkflow: { error, flowLog },
      },
    } = await mutateStartFlow({
      variables: { input: { ...input } },
    })
    if (error) throw new Error(error)
    if (!flowLog) throw new Error('Error starting a new flow')

    if (flowType === workflowConstant.approval.name) {
      if (participantsEmails.includes(user.email)) {
        await executeFlowTransition({
          flowId: flowLog.flowId,
          transitionLabel: workflowConstant.approval.transition.approve,
        })
      }
    }
    return flowLog
  }

  const executeFlowTransition = async (input) => {
    try {
      const {
        data: {
          executeTransition: { error, flowLog },
        },
      } = await mutateExecuteTransition({ variables: { input } })
      if (error) setErrorMessage(error)
      return flowLog
    } catch (error) {
      throw new Error(error)
    }
  }

  const addFlowFiles = async (flow: Flow, uploadingFiles) => {
    if (!flow) return
    try {
      setUploadedFiles((prev) => {
        const newEntries = {}
        for (const [index, file] of uploadingFiles.entries()) {
          newEntries[file.name] = {
            blockId: '',
            progress: 0,
            status: 'uploading',
          }
        }
        return {
          ...prev,
          ...newEntries,
        }
      })

      for await (const [index, file] of uploadingFiles.entries()) {
        const setProgressPercent = (perc: number) => {
          setUploadedFiles((prev) => ({
            ...prev,
            [file.name]: {
              blockId: '',
              progress: perc,
              status: 'uploading',
            },
          }))
        }
        const uploadResponse = await uploadFile(file, setProgressPercent)

        if (!uploadResponse.fileUrl) {
          throw new Error('error uploading file')
        }

        const fileMime = file.type || file.name.split('.').pop() || ''
        let type = blockType.file
        if (fileMime.includes('image')) {
          type = blockType.image
        } else if (fileMime.includes('audio')) {
          type = blockType.audio
        } else if (fileMime.includes('video')) {
          type = blockType.video
        }

        const input = {
          flowId: flow.id,
          name: file.name || '',
          content: uploadResponse.fileUrl,
          preview: uploadResponse.convertedFileUrl || null,
          extension: file.name.split('.').pop() || '',
          order: flow.blocks?.length + index || 0,
          size: file.size,
          type,
        }

        const {
          data: { createBlock },
        } = await mutateAddBlock({ variables: { input } }) // add block to db
        setUploadedFiles((prev) => ({
          ...prev,
          [file.name]: {
            blockId: createBlock.id,
            progress: 100,
            status: 'done',
          },
        }))
      }
    } catch (error) {}
  }

  const removeFlowFile = async (blockId: string) => {
    if (!blockId) return
    await mutateDeleteBlock({ variables: { id: blockId } })
  }

  const submitFeedback = async (flow: Flow | undefined, feedbackMessage) => {
    if (!flow) return
    if (feedbackMessage) {
      await mutateAddBlock({
        variables: {
          input: {
            flowId: flow.id,
            content: feedbackMessage,
            order: -1,
            size: 0,
            type: blockType.text,
          },
        },
      })
    }
    return await executeFlowTransition({
      flowId: flow.id,
      transitionLabel: workflowConstant.feedback.transition.submit,
    })
  }

  const approveFlow = async (flow: Flow | undefined) => {
    if (!flow) return
    return await executeFlowTransition({
      flowId: flow.id,
      transitionLabel: workflowConstant.approval.transition.approve,
    })
  }

  const rejectFlow = async (flow: Flow | undefined, rejectReason?: string) => {
    if (!flow) return
    return await executeFlowTransition({
      flowId: flow.id,
      transitionLabel: workflowConstant.approval.transition.reject,
      metadata: rejectReason && { message: rejectReason },
    })
  }

  const cancelFlow = async (flow: Flow | undefined) => {
    if (!flow) return
    try {
      const {
        data: {
          cancelFlow: { error, flowLog },
        },
      } = await mutateCancelFlow({
        variables: {
          flowId: flow.id,
        },
      })
      if (error) setErrorMessage(error)
      return flowLog
    } catch (error) {
      throw new Error(error)
    }
  }

  const deleteFlow = async (flow: Flow | undefined) => {
    if (!flow) return
    try {
      const {
        data: {
          deleteFlow: { error, flow: deletedFlow },
        },
      } = await mutateDeleteFlow({
        variables: {
          id: flow.id,
        },
      })
      if (error) {
        setErrorMessage(error)
        return
      }
      return deletedFlow
    } catch (error) {
      throw new Error(error)
    }
  }

  const archiveFlow = async (flow: Flow | undefined) => {
    if (!flow) return
    try {
      const {
        data: {
          archiveFlow: { error, flowLog },
        },
      } = await mutateArchiveFlow({
        variables: {
          flowId: flow.id,
        },
      })
      if (error) setErrorMessage(error)
      return flowLog
    } catch (error) {
      throw new Error(error)
    }
  }

  const removeFromFlow = async (flow: Flow | undefined) => {
    if (!flow) return
    try {
      const {
        data: {
          removeMeFromFlow: { error, flowLog },
        },
      } = await mutateRemoveMeFromFlow({
        variables: {
          flowId: flow.id,
        },
      })
      if (error) setErrorMessage(error)
      return flowLog
    } catch (error) {
      throw new Error(error)
    }
  }

  const setFlowReminders = async (flowId, frequency) => {
    if (!flowId || !frequency) return
    try {
      const {
        data: { setFlowIntervalReminders },
      } = await mutateSetFlowIntervalReminders({
        variables: { flowId, frequency },
      })
      return setFlowIntervalReminders
    } catch (error) {
      throw new Error(error)
    }
  }

  const cancelFlowReminders = async (flowId) => {
    if (!flowId) return
    try {
      const {
        data: { cancelFlowReminderFromUser },
      } = await mutateCancelFlowRemindersFromReceiver({
        variables: { flowId },
      })
      return cancelFlowReminderFromUser
    } catch (error) {
      throw new Error(error)
    }
  }

  /**
   * get title of confirmation dialog
   * @param action
   * @returns
   */
  function getConfirmTitle(action: string): string {
    switch (action) {
      case flowActions.rejectFlow:
        return 'Reject the flow'
      case flowActions.cancelFlow:
        return 'Cancel the flow'
      case flowActions.deleteFlow:
        return 'Delete the flow'
      case flowActions.archiveFlow:
        return 'Archive the flow'
      case flowActions.removeFromFlow:
        return 'Remove Me'
      case flowActions.submitFeedback:
        return 'Submit your Input?'
      default:
        return ''
    }
  }

  /**
   * open confirmation dialog
   * @param param
   */
  function startConfirm({ action, flow, payload }: { action: string; flow: Flow; payload?: any }) {
    setIsConfirming(true)
    setConfirmTitle(getConfirmTitle(action))
    setActionType(action)
    setTargetFlow(flow)
    setPayload(payload)
  }

  /**
   * close confirmation dialog
   */
  function endConfirm() {
    setIsConfirming(false)
    setConfirmTitle('')
    setActionType(flowActions.cancelFlow)
    setTargetFlow(undefined)
    setPayload(undefined)
  }

  /*
   * execute action
   */
  async function executeAction() {
    if (!targetFlow || !actionType) return

    try {
      switch (actionType) {
        case flowActions.cancelFlow:
          await cancelFlow(targetFlow)
          break
        case flowActions.deleteFlow:
          await deleteFlow(targetFlow)
          break
        case flowActions.archiveFlow:
          await archiveFlow(targetFlow)
          break
        case flowActions.removeFromFlow:
          await removeFromFlow(targetFlow)
          break
        case flowActions.rejectFlow:
          await rejectFlow(targetFlow, 'Rejected by user')
          break
        case flowActions.approveFlow:
          await approveFlow(targetFlow)
          break
        case flowActions.submitFeedback:
          await submitFeedback(targetFlow, payload.feedbackMessage)
          break
        case flowActions.uploadFiles:
          await addFlowFiles(targetFlow, payload.files)
          break
        case flowActions.removeFile:
          await removeFlowFile(payload.blockId)
          break
        default:
      }
      // close confirmation dialog
      endConfirm()
      // reload flows
      dispatch(fetchFlows())
    } catch (err) {
      Toast.show({ icon: 'error', message: 'Something went wrong.' })
    }
  }

  return {
    isConfirming,
    actionType,
    confirmTitle,
    startConfirm,
    endConfirm,
    executeAction,
    submitFeedback,
    addFlowFiles,
    removeFlowFile,
    createFlow,
    startFlow,
    approveFlow,
    rejectFlow,
    cancelFlow,
    deleteFlow,
    archiveFlow,
    removeFromFlow,
    setUploadedFiles,
    uploadedFiles,
    getFlowReminders,
    setFlowReminders,
    cancelFlowReminders,
  }
}
