import React, { useState, useEffect, Dispatch } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Container,
  Box,
  Flex,
  Heading,
  Text,
  Icon,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  Spinner,
  MenuButton,
  Menu,
  MenuList,
  Portal,
} from '@chakra-ui/react'
import { useClipboard } from 'hooks'
import { useAppSlice, useProjectSlice } from 'features/redux'
import { NodeDetailsInfo, NodeInfoPopover, useNodeMenuActions } from 'features/node'
import { useTrackingEvents } from 'features/trackingEvents'
import { TagsModal } from 'features/tags/components'
import {
  ModalExternalButton,
  ComfirmationModal,
  UpdateModal,
  PopoverModal,
  ShareModalV2,
  IconSection,
  UpdateCoverImageOrColorModal,
  Toast,
  AlertWithAction,
} from 'components'
import { icons, colors } from 'theme'
import { getResizedImage, uploadFile } from 'helpers/storage'
import { formatTimeString, convertNodeColorToHex, minDelay } from 'helpers/utils'
import { menuActions, menuProject, TRACKING_EVENT_TYPE, updateValues } from 'helpers/constant'
import CONFIG from 'helpers/config'
import { EntityType, FollowedNode, Node } from 'types/graphqlSchema'
import ProjectCardMenu from './ProjectCardMenu'

interface IProjectCard {
  projectInfo: Node
  alerts: number
  pageType?: string
  noBorder?: boolean
  setDuplicatingProject: Dispatch<React.SetStateAction<string>>
  setDeletingProject: Dispatch<React.SetStateAction<string>>
  deletingProject: string
}

const ProjectCard = ({
  projectInfo,
  pageType,
  noBorder,
  setDuplicatingProject,
  deletingProject,
  setDeletingProject,
}: IProjectCard) => {
  const { id, coverImage, coverImageSecureUrl, name, createdAt, owner, color } = projectInfo
  const navigate = useNavigate()
  const { copyToClipBoard } = useClipboard()
  // const { hasPermission } = usePermissions()
  const isMobile = useBreakpointValue({ xs: true, lg: false })
  const {
    openModal,
    setOpenModal,
    updateNode,
    deleteNode,
    downloadNode,
    updateFollowedNode,
    duplicateNode,
    leaveSharedNode,
  } = useNodeMenuActions()
  const {
    dispatch,
    user: { followedNodes },
    selectedNode,
    setIsLoading,
    clearSelectedNode,
  } = useAppSlice()
  const { getProject } = useProjectSlice()
  const { addTrackingEvents } = useTrackingEvents()
  const [menuOpen, setMenuOpen] = useState(false)
  const [signedUrl, setSignedUrl] = useState('')
  const [hovering, setHovering] = useState(false)
  const [check, setCheck] = useState(true)
  const {
    isOpen: isOpenLeaveSharedNode,
    onOpen: onOpenLeaveSharedNode,
    onClose: onCloseLeaveSharedNode,
  } = useDisclosure()
  const { isOpen: isOpenMenu, onOpen: onOpenMenu, onClose: onCloseMenu } = useDisclosure()
  const { isOpen: isOpenDeleteNode, onOpen: onOpenDeleteNode, onClose: onCloseDeleteNode } = useDisclosure()

  const initialColor = useColorModeValue(colors.folder.lightGray, colors.folder.darkGray)
  const projectColor = color ? convertNodeColorToHex(color) : initialColor

  const handleClick = () => {
    dispatch(setIsLoading(true))
    dispatch(getProject(id))
    addTrackingEvents([
      {
        entityId: id,
        entityType: EntityType.Node,
        eventType: TRACKING_EVENT_TYPE.VIEW_PROJECT,
      },
    ])
    navigate(`/project/${id}`)
  }

  const handleUpdate = async (updatedValue) => {
    updateNode(updatedValue, projectInfo)
    setOpenModal('')
  }

  const handleDelete = async () => {
    if (projectInfo.category?.includes('PERSONAL')) {
      Toast.show({
        icon: 'check',
        message: 'Project was not deleted.',
      })
    } else {
      setDeletingProject(id)
      await minDelay(deleteNode(projectInfo), 1200)
      setDeletingProject('')
      Toast.show({
        icon: 'check',
        message: 'Project ' + projectInfo.name + ' was moved to Trash.  You can restore it there.',
      })
    }
    setOpenModal('')
  }

  const handleCopyLinkShare = () => {
    if (projectInfo?.id) copyToClipBoard(`${CONFIG.APP.URL}/link/${projectInfo.id}`)
  }

  const handleLeaveSharedNode = async () => {
    const { data } = await leaveSharedNode(id)
    if (data.leaveSharedNode) {
      navigate(0)
    }
  }

  const handleDuplicate = async () => {
    setDuplicatingProject(id)
    await minDelay(duplicateNode(id, 'project'), 1500) // 1 second delay
    onCloseMenu()
    setOpenModal('')
    setDuplicatingProject('')
  }

  const handleMenuAction = async (action: string): Promise<void> => {
    onCloseMenu()
    setOpenModal(action)
    switch (action) {
      case menuActions.follow:
        await updateFollowedNode(id)
        setOpenModal('')
        break
      case menuActions.download:
        await downloadNode(projectInfo)
        setOpenModal('')
        break
      case menuActions.duplicate:
        await handleDuplicate()
        break
      case menuActions.delete:
        setOpenModal('')
        await onOpenDeleteNode()
        break
      case menuActions.copyLink:
        handleCopyLinkShare()
        setOpenModal('')
        break
      case menuActions.unshare:
        setOpenModal('')
        onOpenLeaveSharedNode()
        break
      default:
        break
    }
    setMenuOpen(false)
  }

  useEffect(() => {
    let active = true
    if (coverImageSecureUrl) {
      const url = getResizedImage(coverImageSecureUrl, 'project.main')
      if (url) {
        setSignedUrl(url)
      }
      return () => {
        if (!active) return undefined
        active = false
      }
    } else {
      return undefined
    }
  }, [projectInfo, coverImageSecureUrl, isMobile])

  useEffect(() => {
    return () => {
      dispatch(clearSelectedNode())
    }
  }, [])

  useEffect(() => {
    let timer
    if (check) {
      timer = setTimeout(() => {
        setCheck(false)
      }, 1000)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [check])

  if (deletingProject === id) {
    return <Spinner placeSelf="center" thickness="4px" speed="0.65s" emptyColor="gray.200" color="accent" size="lg" />
  }

  return (
    <Flex
      pos="relative"
      width="full"
      pb={['80%', null, null, '90%']}
      h={0}
      onMouseEnter={() => {
        setHovering(true)
        setCheck(true)
      }}
      onMouseLeave={() => {
        setHovering(false)
        setCheck(true)
      }}
      cursor="pointer"
    >
      <Container
        variant="projectCard"
        maxW="full"
        sx={selectedNode?.id === id ? { borderColor: 'textSoft' } : {}}
        cursor="pointer"
      >
        <Box
          w="full"
          flex={1}
          bg={projectColor.body}
          borderTopRadius={8}
          overflow="hidden"
          pos="relative"
          onClick={handleClick}
        >
          {coverImage ? (
            <Box bgImage={signedUrl} w="full" h="full" backgroundSize="100%" bgRepeat="no-repeat" bgSize="cover" />
          ) : (
            <Flex pos="absolute" left={0} top={0} h="full" w="full" justify="center" align="center" p={6}>
              <Text
                color={projectColor.body === '#5B5B5B' ? 'textSoft' : projectColor.text}
                fontSize={20}
                fontWeight={600}
                textAlign="center"
                noOfLines={3}
                textShadow="0px 2px 8px rgba(26, 26, 26, 0.1)"
              >
                {name}
              </Text>
            </Flex>
          )}
        </Box>
        <Box px={3} pt={[2, null, null, 4]} maxW="100vw" h="72px">
          <Flex align="center" justify="space-between" w="full" maxW="full">
            <Heading
              as="h3"
              textStyle="h3"
              color={useColorModeValue('cardBackgroundAccent', 'white')}
              fontWeight="600"
              textOverflow="ellipsis"
              textAlign="left"
              maxW="100%"
              noOfLines={1}
              flex={1}
            >
              {name}
            </Heading>
          </Flex>
          <Flex
            onDoubleClick={handleClick}
            onClick={() => {
              if (isMobile) {
                handleClick()
              }
            }}
            justify="space-between"
          >
            <Text fontSize={[14, null, 12]} flex={1} noOfLines={1}>
              {formatTimeString(createdAt)} by {owner?.firstName} {owner?.lastName}
            </Text>
          </Flex>
        </Box>

        {/* BOTTOM MENUS */}
        <Flex
          pos="absolute"
          bottom={isMobile ? '-2rem' : '-2.5rem'}
          align="center"
          justify="space-between"
          width="100%"
          opacity={isMobile || hovering ? 1 : 0}
          transition="opacity 0.2s"
          p={0}
        >
          <Box>
            <IconSection
              node={projectInfo}
              isMobile={isMobile || false}
              followedNodes={followedNodes}
              nodeNotifications={0}
            />
          </Box>

          <Flex
            dir="row"
            onClick={(event) => {
              event.stopPropagation()
            }}
          >
            {!isMobile && (
              <NodeInfoPopover node={projectInfo} setOpenModal={setOpenModal}>
                <Icon
                  as={icons.infoIcon}
                  color="textRegular"
                  h={[3, null, null, 5]}
                  w={[3, null, null, 5]}
                  mr={2}
                  p={0}
                  _hover={{
                    color: 'white',
                  }}
                />
              </NodeInfoPopover>
            )}

            {isMobile ? (
              <PopoverModal menuOpen={menuOpen} setMenuOpen={setMenuOpen} iconSize={{ height: 5, width: 5 }}>
                <ProjectCardMenu
                  projectInfo={projectInfo}
                  follow={followedNodes?.some((f: FollowedNode) => f.nodeId === id)}
                  handleMenuAction={handleMenuAction}
                  pageType={pageType !== undefined ? menuProject[pageType] : menuProject.base}
                  noBorder={noBorder}
                  isMobile={isMobile}
                />
              </PopoverModal>
            ) : (
              <Menu isOpen={isOpenMenu} onClose={onCloseMenu} onOpen={onOpenMenu}>
                <MenuButton
                  display="flex"
                  alignItems="center"
                  bg="transparent"
                  fill="textRegular"
                  _hover={{ fill: 'textSoft' }}
                >
                  <Icon as={icons.menuIcon} w={6} h={6} p={0} mt={2} fill="inherit" />
                </MenuButton>
                <Portal>
                  <MenuList p={0} m={0} zIndex={2}>
                    <ProjectCardMenu
                      projectInfo={projectInfo}
                      follow={followedNodes?.some((f: FollowedNode) => f.nodeId === id)}
                      handleMenuAction={handleMenuAction}
                      pageType={pageType !== undefined ? menuProject[pageType] : menuProject.base}
                      noBorder={noBorder}
                    />
                  </MenuList>
                </Portal>
              </Menu>
            )}
          </Flex>
        </Flex>

        {/* Update photo or color */}
        <ModalExternalButton
          header={updateValues[openModal]?.header}
          isOpen={openModal === menuActions.coverImage}
          close={() => {
            setOpenModal('')
          }}
        >
          <UpdateCoverImageOrColorModal
            currentCoverImage={coverImageSecureUrl}
            currentFolderColor={color}
            onCancel={() => setOpenModal('')}
            onUpdateColor={(newColor) => {
              // update color
              setOpenModal('')
              updateNode({ color: newColor, coverImage: null }, projectInfo)
            }}
            onUpdateCoverImage={async (newCoverImage) => {
              // update cover image
              setOpenModal('')
              const uploadResponse = await uploadFile(newCoverImage)
              updateNode({ color: null, coverImage: uploadResponse.fileUrl }, projectInfo)
            }}
          />
        </ModalExternalButton>

        {/* Update name */}
        <ModalExternalButton
          header={updateValues[openModal]?.header}
          isOpen={openModal === menuActions.rename}
          close={() => {
            setOpenModal('')
          }}
        >
          <UpdateModal
            placeholder={updateValues[openModal]?.placeholder}
            label={updateValues[openModal]?.label}
            name="projectCover"
            startValue={name}
            onCancel={() => {
              setOpenModal('')
            }}
            onUpdate={handleUpdate}
          />
        </ModalExternalButton>

        {/* DELETE File */}
        <AlertWithAction
          action={handleDelete}
          isOpen={isOpenDeleteNode}
          onClose={onCloseDeleteNode}
          actionText={`Delete the project "${projectInfo?.name}"?`}
          warningText={[`"${projectInfo?.name}" will be moved to the trash.`]}
          confirmButtonText="Delete"
        />

        <TagsModal
          nodeType="Project"
          entityId={projectInfo.id}
          entityName={projectInfo.name}
          entityType={EntityType.Node}
          isOpen={openModal === menuActions.tag}
          onClose={() => {
            setOpenModal('')
          }}
        />

        {/* MOBILE DETAILS */}
        <ModalExternalButton
          header="Project Details"
          isOpen={openModal === menuActions.details}
          close={() => {
            setOpenModal('')
          }}
          size="full"
          modelWidth="full"
          rounded={0}
          headerBg="background"
          isCentered={false}
        >
          <NodeDetailsInfo node={projectInfo} collapsed={false} setOpenModal={setOpenModal} />
        </ModalExternalButton>

        {/* Share Project */}
        <ShareModalV2
          isOpen={openModal === menuActions.share}
          onClose={() => {
            setOpenModal('')
          }}
          node={projectInfo}
          setOpenModal={setOpenModal}
        />
        {/* Remove Myself */}
        <AlertWithAction
          action={handleLeaveSharedNode}
          isOpen={isOpenLeaveSharedNode}
          onClose={onCloseLeaveSharedNode}
          actionText="Remove Myself"
          warningText={['You will no longer have access to this project.']}
        />
      </Container>
    </Flex>
  )
}

export default ProjectCard
