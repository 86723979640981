import React, { useEffect } from 'react'
import { useQuery, useSubscription, ApolloError } from '@apollo/client'
import { Box, Grid, GridItem, useBreakpointValue } from '@chakra-ui/react'
import { Outlet, useLocation } from 'react-router-dom'
import { ModalExternalButton, Menus, RecordVoiceMemo, SubHeader } from 'components'
import { useNodeMenuActions } from 'features/node/hooks'
import { useAppSlice, useProjectSlice, useWorkflowSlice } from 'features/redux'
import { GET_FLOWS, FLOWS_SUBSCRIPTION } from 'features/graphql'
import { CreateNewProject, CreateNewFolder } from 'pages/components'

export default () => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const { pathname } = useLocation()
  const { dispatch, clearSelectedNode } = useAppSlice()
  const { getProjects } = useProjectSlice()
  const { getWorkflows, setFlows } = useWorkflowSlice()
  const { setOpenModal, openModal } = useNodeMenuActions()
  // Later move subheader to each particular page, instead of this switch
  const noSubHeader = pathname === '/flows' || pathname === '/search' || pathname === '/dashboard'

  useEffect(() => {
    dispatch(clearSelectedNode())
  }, [pathname])

  // GraphQL Call for Flows
  const { refetch } = useQuery(GET_FLOWS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ flows }) => {
      dispatch(setFlows(flows))
    },
    onError: (error) => {
      throw new Error(error.message)
    },
  })

  // Subscription to Dynamically update flows
  useSubscription(FLOWS_SUBSCRIPTION, {
    // variables: { nodeId: node.id },
    onData: () => {
      refetch()
    },
    onError: (error: ApolloError) => {
      console.log(error.message)
    },
  })

  useEffect(() => {
    ;(async () => {
      await dispatch(getWorkflows())
    })()
  }, [])

  useEffect(() => {
    const loadProjects = async () => {
      const params = {
        filter: {
          type: 'multi_conditions',
          conditions: { type: 'PROJECT', state: 'ACTIVE' },
        },
      }
      await dispatch(getProjects(params))
    }
    loadProjects()
  }, [])

  return (
    <Box w="full" p={0}>
      <Grid
        flexDir="column"
        h="full"
        minH="99.8vh"
        templateRows={[`${noSubHeader ? '0px' : '65px'} auto 75px`, null, null, '85px auto 75px', 'auto 1fr', null]}
        templateColumns={['100vw', null, null, null, 'min-content 1fr']}
        bg="background"
        w="full"
      >
        {/* Placeholder for Left Menu */}
        <GridItem
          width="236px"
          gridRow={['4/5', null, null, null, '1/4']}
          gridColumn={[null, null, null, '1/2']}
          h="full"
          bg={['background', null, null, null, 'menuBackground']}
          display={'block'}
        />
        {/* Main Content */}
        <GridItem px={isMobile ? 0 : 6}>
          {!noSubHeader && <SubHeader openModal={openModal} setOpenModal={setOpenModal} hideActions={isMobile} />}
        </GridItem>
        <GridItem w="full" bg="background" px={isMobile ? 0 : 6}>
          <Outlet />
        </GridItem>
      </Grid>

      {/* Fixed Left Menu */}
      <Box
        pos="fixed"
        bottom={0}
        w={{ base: 'full', lg: 'auto' }}
        h={{ base: 'auto', lg: 'full' }}
        bg="menuBackground"
        zIndex={1}
      >
        <Menus />
      </Box>

      {/* NEW PROJECT */}
      <ModalExternalButton header="New Project" isOpen={openModal === 'newProject'} close={() => setOpenModal('')}>
        <CreateNewProject setOpenModal={setOpenModal} />
      </ModalExternalButton>

      {/* NEW FOLDER */}
      <ModalExternalButton header="New Folder" isOpen={openModal === 'newBox'} close={() => setOpenModal('')}>
        <CreateNewFolder openModal={openModal} setOpenModal={setOpenModal} />
      </ModalExternalButton>

      {/** RECORD AUDIO */}
      {/* <ModalExternalButton header="" isOpen={openModal === 'recordVoiceMemo'} close={() => setOpenModal('')}>
        <RecordVoiceMemo setOpenModal={setOpenModal} setShouldRefresh={setShouldRefresh} />
      </ModalExternalButton> */}
    </Box>
  )
}
