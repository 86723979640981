import { useState, useEffect } from 'react'
import {
  Text,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
  Fade,
  useBreakpointValue,
  Button,
} from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'
import { Node, User } from 'types/graphqlSchema'
import ShareModalShareTab from './ShareModalShareTab'
import ShareModalLinkTab from './ShareModalLinkTab'
import { GET_NODE, useNodeMenuActions } from 'features/node'
import { useQuery } from '@apollo/client'
import { Toast } from 'components'

interface ShareModalProps {
  isOpen: boolean
  onClose: () => void
  node: Node | null | undefined
  setOpenModal: React.Dispatch<React.SetStateAction<string>>
}

const TabStyles = {
  fontSize: 'inherit',
  p: '0 0 8px 0 ',
  color: 'textBlack',
  _hover: { color: 'cardBackground' },
  _selected: { color: 'textBlack', borderColor: 'borderRegular' },
}

const ShareModalV2 = ({ isOpen, onClose, node, setOpenModal }: ShareModalProps) => {
  const [tabIndex, setTabIndex] = useState(0)
  const [containerHeight, setContainerHeight] = useState(300)
  const isMobile = useBreakpointValue({ base: true, md: false })
  const [shareState, setShareState] = useState({
    sentState: '',
    sharerMessage: '',
    sent: false,
  })
  const [recipientsToList, setRecipientsToList] = useState<User[]>([])
  const [showEditPane, setShowEditPane] = useState(false)
  const [selectOpen, setSelectOpen] = useState(false)
  const [textAreaExpanded, setTextAreaExpanded] = useState(false)

  const { refetch } = useQuery(GET_NODE, {
    variables: { id: node?.id },
  })

  const { shareNode } = useNodeMenuActions()

  useEffect(() => {
    if (tabIndex === 1) {
      setContainerHeight(140)
    }
  }, [tabIndex])

  const handleTabsChange = (index) => {
    setTabIndex(index)
  }

  const handleShareSent = async () => {
    if (!recipientsToList) return
    const alreadySharedEmails = node.sharedMembers?.map((sm) => sm?.sharedWith?.email) || []
    const recipientEmails = recipientsToList.filter((r) => !alreadySharedEmails.includes(r.email))
    const newEmails = recipientEmails.map((u) => u.email)
    if (newEmails.length === 0) {
      setShareState((prev) => ({ ...prev, sentState: 'Recipients have been shared', sent: true }))
      setContainerHeight(300)
      return
    }
    const uniqueEmails = [...new Set(newEmails)].join(', ')
    const res = await shareNode(uniqueEmails, node.id, shareState.sharerMessage)
    if (res?.id) {
      setOpenModal('')
      Toast.show({
        icon: 'check',
        message: 'Recipients Added',
      })
    } else {
      setShareState((prev) => ({ ...prev, sentState: "Error - Couldn't Share" }))
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
        setShareState((prev) => ({ ...prev, sentState: '', sharerMessage: '', sent: false }))
        setTabIndex(0)
      }}
      isCentered
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent
        // display={'flex'}
        // flexDir={'column'}
        justifyContent={'center'}
        bg={'textHighlight'}
        color={'textBlack'}
        fontFamily={'Inter'}
        w={'600px'}
        maxW={'80%'}
        py={2}
      >
        <Fade in={shareState.sentState?.length > 0} transition={{ enter: { duration: 1 }, exit: { duration: 0.6 } }}>
          <Flex
            position={'absolute'}
            top={0}
            width={'full'}
            border={'none'}
            h={'46px'}
            bg={'background'}
            align={'center'}
            justify={'center'}
            gap={3}
            borderTopRadius={5}
          >
            {shareState.sentState === 'Recipients Added' && (
              <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1 6.62857L5.27143 11L11.6786 1"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}

            <Text color="textHighlight" fontSize={14}>
              {shareState.sentState}
            </Text>
          </Flex>
        </Fade>
        <ModalHeader py={0} display={'flex'} alignItems={'baseline'} fontSize={18} mx={4} mt={2}>
          <Input
            fontSize={'inherit'}
            placeholder=""
            value={node?.name}
            disabled={true}
            variant="tipSaveModalTitle"
            color="textBlack"
            _disabled={{ color: 'cardBackground' }}
            opacity={shareState.sentState.length > 0 ? 0 : 1}
          />
        </ModalHeader>
        <ModalCloseButton color={shareState.sentState.length > 0 ? 'textHighlight' : 'cardBackground'} />

        <ModalBody display={'flex'} flexDirection={'column'} mx={5} px={0}>
          <Tabs mt={tabIndex === 99 ? 2 : 0} isLazy index={tabIndex} onChange={handleTabsChange}>
            <TabList
              bg="inherit"
              fontSize={'14px'}
              border={'none'}
              gap={6}
              mx={6}
              mb="1.5rem"
              color="textBlack"
              borderBottom={tabIndex !== 99 ? '1px solid var(--chakra-colors-borderLight)' : ''}
            >
              <Tab {...TabStyles}>Share With</Tab>
              <Tab {...TabStyles}>Get Link</Tab>
            </TabList>
            <motion.div
              layout
              key="shareModal"
              aria-label="Share"
              initial={{ height: 30, opacity: 0 }}
              animate={{
                height: containerHeight,
                opacity: 1,
              }}
              transition={{
                type: 'ease',
                duration: 0.7,
                opacity: { delay: 0.6 },
              }}
              style={{
                borderRadius: '6px',
                display: 'flex',
                flexDirection: 'column',
                gap: isMobile ? '.5rem' : '1.5rem',
                width: '100%',
                fontSize: isMobile ? '12px' : '14px',
                overflow: 'hidden',
              }}
            >
              <TabPanels>
                <TabPanel as={AnimatePresence}>
                  <ShareModalShareTab
                    node={node}
                    shareState={shareState}
                    setShareState={setShareState}
                    setContainerHeight={setContainerHeight}
                    containerHeight={containerHeight}
                    recipientsToList={recipientsToList}
                    setRecipientsToList={setRecipientsToList}
                    textAreaExpanded={textAreaExpanded}
                    setTextAreaExpanded={setTextAreaExpanded}
                    selectOpen={selectOpen}
                    setSelectOpen={setSelectOpen}
                    showEditPane={showEditPane}
                    setShowEditPane={setShowEditPane}
                  />
                </TabPanel>
                <TabPanel as={AnimatePresence}>
                  <ShareModalLinkTab node={node} />
                </TabPanel>
              </TabPanels>
            </motion.div>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <Flex alignSelf={'flex-end'} minHeight={'44px'}>
            {!showEditPane && !selectOpen ? (
              <Button
                isDisabled={!recipientsToList?.length}
                onClick={() => handleShareSent()}
                variant={'defaultButton'}
                _disabled={{
                  bg: 'var(--chakra-colors-textSoft)',
                  color: 'var(--chakra-colors-textRegular)',
                  border: '1px solid transparent',
                  _hover: {
                    backgroundColor: 'var(--chakra-colors-textSoft)',
                    color: 'var(--chakra-colors-textRegular)',
                    border: '1px solid textBlack',
                    cursor: 'not-allowed',
                  },
                }}
                fontWeight={500}
              >
                Share
              </Button>
            ) : (
              <Button
                variant={'outline'}
                color="textBlack"
                maxW="max"
                alignSelf={'flex-end'}
                onClick={() => setShowEditPane(false)}
              >
                Done
              </Button>
            )}
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ShareModalV2
