import { Flex, useBreakpointValue, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import { isSafari } from 'react-device-detect'
import { Button, IconButton, IconPopover, ButtonPopover } from 'components'
import { menuActions } from 'helpers/constant'
import { usePermissions } from 'hooks'
import { customIcons, icons } from 'theme'
import DotActionsMenu from './components/DotActionsMenu'
import NewActionsMenu from './components/NewActionsMenu'
import { Node } from 'types/graphqlSchema'
import { useAppSlice } from 'features/redux'

interface ActionBarProps {
  currentNode?: Node
  openModal: string
  setOpenModal: (openModal: string) => void
  pathname: string
  currentTip?: Node
  follow: boolean
  handleMenuAction: (action: string) => void
  disableMenuOptions: string[]
}

const ActionBar = ({
  openModal,
  setOpenModal,
  pathname,
  currentTip,
  currentNode,
  follow,
  handleMenuAction,
  disableMenuOptions,
}: ActionBarProps) => {
  const isMobile = useBreakpointValue({
    xs: true,
    sm: true,
    md: true,
    lg: false,
  })
  const mobileShareIcon = 'textHighlight'
  const { hasPermission } = usePermissions()
  const { user } = useAppSlice()
  const isSharedFolder =
    currentNode && currentNode.type === 'BOX'
      ? currentNode.sharedMembers?.some((sm) => sm?.sharedWithId === user.id && user.id !== currentNode.ownerId)
      : false
  const newActionsMenuPopover = useDisclosure()

  const showCreateNewItem = !pathname.includes('/file/') && !currentTip
  const showDownloadBtn = !!currentTip
  const showShareBtn = !pathname.includes('/personal')

  if (isMobile) {
    return (
      <Flex align="center">
        {showShareBtn && (
          <Flex width="30px">
            <IconButton
              ariaLabel="shareButton"
              icon={customIcons.menuShare}
              variant="passwordBtn"
              iconSize={{ height: 5, width: 5 }}
              iconMarginRight={2}
              style={{
                color: mobileShareIcon,
                padding: 0,
                width: '30px',
              }}
              onClick={() => handleMenuAction(menuActions.share)}
              isDisable={!hasPermission('share')}
            />
          </Flex>
        )}
        <IconPopover
          icon={icons.menuIcon}
          iconSize={{ width: 6, height: 6 }}
          iconColor={useColorModeValue('', 'white')}
          variantBtn={'passwordBtn'}
          testId="action-bar-dot-popover-button"
          xMargin={0}
          buttonStyle={{
            width: '48px',
            height: '48px',
            marginLeft: 4,
            marginRight: 0,
            padding: 0,
            color: 'accent',
          }}
        >
          <DotActionsMenu follow={follow} handleMenuAction={handleMenuAction} disableMenuOptions={disableMenuOptions} />
        </IconPopover>
      </Flex>
    )
  }

  return (
    <Flex align="center">
      {showCreateNewItem && (
        <ButtonPopover
          variantBtn="btnOutline"
          rightIcon={<icons.FaChevronDown />}
          buttonLabel="New"
          marginXBtn="0"
          buttonStyle={{
            width: '5.75rem',
            height: '3rem',
            padding: 0,
            marginLeft: 4,
          }}
          isDisable={!hasPermission('update') && !hasPermission('upload')}
          isOpen={newActionsMenuPopover.isOpen}
          onOpen={newActionsMenuPopover.onOpen}
          onClose={newActionsMenuPopover.onClose}
        >
          <NewActionsMenu
            setOpenModal={setOpenModal}
            openModal={openModal}
            handleMenuAction={async (action) => {
              await handleMenuAction(action)
              newActionsMenuPopover.onClose()
            }}
          />
        </ButtonPopover>
      )}

      {showDownloadBtn && (
        <Button
          variant="btnOutline"
          leftIcon={<icons.downLoad />}
          label="Download"
          style={{
            width: '5.75rem',
            height: '3rem',
            padding: 0,
            fontSize: '15.7px',
            letterSpacing: isSafari ? '-1px' : 'normal',
            marginLeft: 4,
          }}
          onClick={() => handleMenuAction(menuActions.download)}
          disabled={!hasPermission('download')}
        />
      )}

      {showShareBtn && (
        <Button
          label="Share"
          variant="defaultButton"
          disabled={!hasPermission('share')}
          onClick={() => handleMenuAction(menuActions.share)}
          style={{
            width: '5.75rem',
            height: '3rem',
            marginLeft: 4,
            padding: 0,
            fontSize: '15.7px',
            letterSpacing: isSafari ? '-1px' : 'normal',
          }}
        />
      )}

      <IconPopover
        icon={icons.menuIcon}
        iconSize={{ width: 6, height: 6 }}
        iconColor={useColorModeValue('', 'white')}
        variantBtn={'btnOutline'}
        testId="action-bar-dot-popover-button"
        xMargin={0}
        buttonStyle={{
          width: '3rem',
          height: '3rem',
          marginLeft: 4,
          marginRight: 0,
          padding: 0,
          color: 'accent',
        }}
      >
        <DotActionsMenu
          isSharedWithMe={isSharedFolder}
          follow={follow}
          handleMenuAction={handleMenuAction}
          disableMenuOptions={disableMenuOptions}
        />
      </IconPopover>
    </Flex>
  )
}

export default ActionBar
