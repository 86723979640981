import { orderBy } from 'lodash'
import { workflowConstant } from 'helpers/constant'
import { Flow, User } from 'types/graphqlSchema'
import { FlowStatus } from '../Flows.types'

export default function useFlowContents() {
  // HELPERS
  const unresolvedStatus = (flow) => {
    const { flowLogs, participants } = flow
    if (participants.length === 0) {
      return 'Unresolved'
    }
    const approvalParticipants = flowLogs.filter(
      (log) => log.toState?.stateName === workflowConstant.approval.state.approved,
    )
    return `${approvalParticipants.length} of ${participants.length} Approved`
  }

  const getDoneStateBeforeArchived = (archivedflow) => {
    const {
      workflow: { name },
      state: { stateName },
      flowLogs,
      participants,
    } = archivedflow
    const statesInDone = {
      [workflowConstant.approval.name]: [
        workflowConstant.approval.state.approved,
        workflowConstant.approval.state.rejected,
        workflowConstant.approval.state.unresolved,
        workflowConstant.approval.state.cancelled,
      ],
      [workflowConstant.feedback.name]: [
        workflowConstant.feedback.state.submitted,
        workflowConstant.feedback.state.unresolved,
        workflowConstant.feedback.state.cancelled,
      ],
    }
    const doneLogs = flowLogs.filter((log) => statesInDone[name].includes(log.toState.stateName))
    if (name === workflowConstant.approval.name) {
      // Approval flow
      if (doneLogs.some((log) => log.toState.stateName === workflowConstant.approval.state.cancelled)) {
        // Cancelled
        return workflowConstant.approval.state.cancelled
      } else if (doneLogs.every((log) => log.toState.stateName === workflowConstant.approval.state.approved)) {
        // Approved
        return workflowConstant.approval.state.approved
      } else if (doneLogs.every((log) => log.toState.stateName === workflowConstant.approval.state.rejected)) {
        // Rejected
        return workflowConstant.approval.state.rejected
      } else if (
        (doneLogs.some((log) => log.toState.stateName === workflowConstant.approval.state.rejected) &&
          doneLogs.some((log) => log.toState.stateName === workflowConstant.approval.state.approved)) ||
        participants.length === 0
      ) {
        // Unresolved
        return unresolvedStatus({ flowLogs: doneLogs, participants })
      }
    } else if (name === workflowConstant.feedback.name) {
      // Feedback flow
      if (doneLogs.some((log) => log.toState.stateName === workflowConstant.feedback.state.cancelled)) {
        // Cancelled
        return workflowConstant.feedback.state.cancelled
      } else if (doneLogs.every((log) => log.toState.stateName === workflowConstant.feedback.state.submitted)) {
        // Submitted
        return `Input ${workflowConstant.feedback.state.submitted}`
      }
      return workflowConstant.feedback.state.unresolved
    }
    // more flow type
    return stateName
  }

  /**
   * return flow status title
   * @param param
   */
  const getFlowTitleByStatus = ({ flow, status }: { flow: Flow; status?: FlowStatus }) => {
    const isApprovalFlow = flow.workflow?.name === workflowConstant.approval.name
    const isFeedbackFlow = flow.workflow?.name === workflowConstant.feedback.name
    const isSignatureFlow = flow.workflow?.name === workflowConstant.signature.name

    // TO DO
    if (status === FlowStatus.todo) {
      if (isApprovalFlow) {
        return `Your Approval's Requested`
      }
      if (isFeedbackFlow) {
        return `Your Input's Needed`
      }
      if (isSignatureFlow) {
        return `Your Signature's Needed`
      }
      return ''
    }
    // IN PROGRESS
    if (status === FlowStatus.inProgress) {
      if (isApprovalFlow) {
        return `Others to Approve`
      }
      if (isFeedbackFlow) {
        return `Others to Submit Input`
      }
      if (isSignatureFlow) {
        return `Others to Sign`
      }
      return ''
    }
    // IN THE LOOP
    if (status === FlowStatus.loop) {
      // return 'People in the loop'
      if (isApprovalFlow) {
        return `Others to Approve`
      }
      if (isFeedbackFlow) {
        return `Others to Submit Input`
      }
      if (isSignatureFlow) {
        return `Others to Sign`
      }
      return ''
    }
    // RESULT
    if (status === FlowStatus.result) {
      if (isApprovalFlow && flow.state?.stateName === workflowConstant.approval.state.unresolved) {
        return unresolvedStatus(flow)
      }
      if (isFeedbackFlow && flow.state?.stateName === workflowConstant.feedback.state.submitted) {
        return `Input Submitted`
      }
    }
    // ARCHIVED
    if (flow.state?.stateName === 'Archived') {
      return getDoneStateBeforeArchived(flow)
    }
    if (isFeedbackFlow && flow.state?.stateName === workflowConstant.feedback.state.submitted) {
      return `Input Submitted`
    }

    return flow.state?.stateName || ''
  }

  // https://tipbox.atlassian.net/browse/TA-867
  const getFlowStatusForRequester = (flow) => {
    const {
      workflow: { name },
      state: { stateName },
    } = flow
    if (stateName === 'Archived') {
      return getDoneStateBeforeArchived(flow)
    }
    if (name === 'Approval') {
      if (stateName === 'Pending') {
        return 'Approval Pending'
      } else if (stateName === 'Unresolved') {
        return unresolvedStatus(flow)
      }
    } else if (name === 'Feedback') {
      if (stateName === 'Pending') {
        return 'Input Pending'
      } else if (stateName === 'Submitted') {
        return 'Input Submitted'
      }
    }
    return stateName
  }

  // https://tipbox.atlassian.net/browse/TA-867
  const getFlowStatusForParticipant = (flow) => {
    const {
      workflow: { name },
      state: { stateName },
    } = flow
    if (stateName === 'Archived') {
      return getDoneStateBeforeArchived(flow)
    }
    if (name === 'Approval' && stateName === 'Unresolved') {
      return unresolvedStatus(flow)
    }
    if (name === 'Feedback' && stateName === 'Submitted') {
      return 'Input Submitted'
    }
    return stateName
  }

  const getParticipantState = (
    flow: Flow,
    participant: User,
  ): { state: string; workflowState: string; createdAt?: string; reason?: string } => {
    const participantLogs = orderBy(
      flow?.flowLogs?.filter((log) => log?.user?.id === participant.id),
      'createdAt',
      'desc',
    )
    const positiveLog = participantLogs.find(
      (log) =>
        log &&
        (log.toState?.stateName === workflowConstant.approval.state.approved ||
          log.toState?.stateName === workflowConstant.feedback.state.submitted),
    )
    const negativeLog = participantLogs.find(
      (log) =>
        log &&
        (log.toState?.stateName === workflowConstant.approval.state.rejected ||
          log.toState?.stateName === 'Cancelled' ||
          log.toState?.stateName === 'Unresolved'),
    )
    if (positiveLog) {
      return {
        state: 'Positive',
        workflowState: positiveLog.toState?.stateName || '',
        createdAt: positiveLog.createdAt,
      }
    }
    if (negativeLog) {
      return {
        state: 'Negative',
        workflowState: negativeLog.toState?.stateName || '',
        createdAt: negativeLog.createdAt,
        reason: negativeLog.metadata?.message,
      }
    }
    return { state: 'Pending', workflowState: 'Pending' }
  }

  return {
    getFlowTitleByStatus,
    getFlowStatusForRequester,
    getFlowStatusForParticipant,
    getParticipantState,
  }
}
