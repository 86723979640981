import React, { useState } from 'react'
import { IconType } from 'react-icons'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Flex,
  PopoverProps,
  Icon,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react'
import { Button, IconButton } from 'components'

interface SelectPopoverProps extends PopoverProps {
  options: { label: string; icon?: IconType }[]
  varinantPopover?: string
  variantBtn?: string
  setCurrentOption: (currentOption: string) => void
  currentOption: string
  triggerIcon?: IconType
  iconColor?: string
  iconSize?: { width: number; height: number }
  iconButton?: boolean
  btnStyle?: React.CSSProperties
  triggerAlign?: string
}

const SelectPopover = ({
  options,
  variantBtn = 'selectTriggerBtn',
  varinantPopover = 'autoWidth',
  setCurrentOption,
  currentOption,
  triggerIcon,
  iconColor,
  iconSize = { width: 4, height: 4 },
  iconButton = false,
  btnStyle,
  triggerAlign = 'center',
}: SelectPopoverProps) => {
  const [closeable, setCloseable] = useState(true)
  const { isOpen, onClose, onToggle } = useDisclosure()
  const defaultIconColor = useColorModeValue('cardBackgroundAccent', 'textRegular')
  const iColor = iconColor || defaultIconColor

  return (
    <Popover variant={varinantPopover} placement="bottom-start" isOpen={isOpen} isLazy>
      <PopoverTrigger>
        <Flex align={triggerAlign} onClick={onToggle} mr={4}>
          <Button label={currentOption} onClick={() => {}} variant={variantBtn} style={{ ...btnStyle }} />
          {triggerIcon && (
            <Icon as={triggerIcon} pt="1px" ml={2} color={iColor} w={iconSize?.width} h={iconSize?.height} />
          )}
        </Flex>
      </PopoverTrigger>
      <PopoverContent
        p={0}
        _focus={{ boxShadow: 'none' }}
        borderRadius={8}
        bg="textHighlight"
        color="textBlack"
        fontWeight={400}
        onMouseLeave={() => {
          setCloseable(true)
        }}
        onMouseEnter={() => {
          setCloseable(false)
        }}
        onBlur={() => {
          if (closeable) onClose()
        }}
      >
        <PopoverBody p={0} borderRadius={8}>
          <Flex flexDir="column">
            {options.map((option) => (
              <React.Fragment key={option.label}>
                {iconButton ? (
                  <IconButton
                    ariaLabel={option.label}
                    icon={option.icon}
                    label={option.label}
                    iconSize={{ width: 4, height: 4 }}
                    justify={['space-between']}
                    flexDir="row-reverse"
                    variant="selectTriggeroptions"
                    onClick={() => {
                      setCurrentOption(option.label)
                      onClose()
                    }}
                    buttonLabelWidth="full"
                  />
                ) : (
                  <Button
                    label={option.label}
                    variant="selectTriggeroptions"
                    onClick={() => {
                      setCurrentOption(option.label)
                      onClose()
                    }}
                  />
                )}
              </React.Fragment>
            ))}
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default SelectPopover
