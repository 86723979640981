// SVG
import approval from 'assets/approval.svg'
import approvalCheck from 'assets/approval_check.svg'
import approvalDetails from 'assets/approval_details.svg'
import approvalDetailsRed from 'assets/approval_details_red.svg'
import arrowUp from 'assets/arrow_up.svg'
import assignOthers from 'assets/assign_others.svg'
import attachmentClip from 'assets/attachment-clip.svg'
import authBg from 'assets/auth_background.webp'
import blank from 'assets/blank.svg'
import bookmark from 'assets/bookmark.svg'
import bookmarkPlus from 'assets/bookmark_plus.svg'
import box from 'assets/box.svg'
import boxWhite from 'assets/box_white.svg'
import boxLogo from 'assets/box_logo.svg'
import boxLogoDark from 'assets/box_logo_dark.svg'
import calendar from 'assets/calendar_default.svg'
import calendarGrey from 'assets/calendar_grey.svg'
import calendarGreyThick from 'assets/calendar_grey_thick.svg'
import calendarHighlight from 'assets/calendar_highlight.svg'
import calendarRed from 'assets/calendar_red.svg'
import calendarRedThick from 'assets/calendar_red_thick.svg'
import camera from 'assets/camera.svg'
import checkbox from 'assets/checkbox.svg'
import checkmark from 'assets/checkmark.svg'
import chevronRedDown from 'assets/chevron-red-down.svg'
import chevronRightBreadcrumb from 'assets/chevron_right_breadcrumb.svg'
import circleCheck from 'assets/circle-check.svg'
import circlePlus from 'assets/circle_plus.svg'
import clockGrey from 'assets/clock_grey.svg'
import clockRed from 'assets/clock_red.svg'
import comments from 'assets/comments_v2.svg'
import details from 'assets/details_v2.svg'
import documentSavedCheckmark from 'assets/document_saved_checkmark.svg'
import download from 'assets/download.svg'
import dragAndDrop from 'assets/drag_and_drop.svg'
import ellipsesBlack from 'assets/ellipses_black.svg'
import emailNotification from 'assets/email_notification.svg'
import emailNotificationV2 from 'assets/email_notification_v2.svg'
import emptyFavorites from 'assets/empty_favorites.svg'
import emptyFlows from 'assets/empty_flows.svg'
import emptyFollowing from 'assets/empty_following.svg'
import emptyPersonal from 'assets/empty_my_box.svg'
import emptyProject from 'assets/empty_project_tip.svg'
import emptyProjects from 'assets/search_no_results.svg'
import emptyRecent from 'assets/empty_recent_tip.svg'
import emptyTip from 'assets/empty_detail_icon.svg'
import emptyTrash from 'assets/empty_trash.svg'
import errorPic from 'assets/error_pic.svg'
import exclamationRed from 'assets/exclamation_red.svg'
import exclamationWhite from 'assets/exclamation_white.svg'
import failureMark from 'assets/failure_mark.svg'
import fileAi from 'assets/file_ai.svg'
import fileBmp from 'assets/file_bmp.svg'
import fileCsv from 'assets/file_csv.svg'
import fileDoc from 'assets/file_doc.svg'
import fileEps from 'assets/file_eps.svg'
import fileFdr from 'assets/file_fdr.svg'
import fileImg from 'assets/file_img.svg'
import fileInd from 'assets/file_ind.svg'
import fileMmx from 'assets/file_mmx.svg'
import fileMovie from 'assets/file_movie.svg'
import fileOne from 'assets/file_one.svg'
import filePdf from 'assets/file_pdf.svg'
import filePot from 'assets/file_pot.svg'
import filePs from 'assets/file_ps.svg'
import fileRaw from 'assets/file_raw.svg'
import fileRtf from 'assets/file_rft.svg'
import fileSvg from 'assets/file_svg.svg'
import fileTipbox from 'assets/file_tipbox.svg'
import fileTxt from 'assets/file_txt.svg'
import fileUnknown from 'assets/file_unkown.svg'
import fileWav from 'assets/file_wav.svg'
import fileXlsx from 'assets/file_xlsx.svg'
import flowConfirm from 'assets/flow_confirm.svg'
import flowToggleNegative from 'assets/flowToggleNegative.svg'
import flowTogglePositive from 'assets/flowTogglePositive.svg'
import flowToggleExclamation from 'assets/flowToggleExclamation.svg'
import flowUrgent from 'assets/flow_urgent.svg'
import flows from 'assets/flows.svg'
import flowsV2 from 'assets/flows_v2.svg'
import flowsBoltRed from 'assets/flows_bolt_red.svg'
import folder from 'assets/folder.svg'
import folderDefault from 'assets/folder_default.svg'
import following from 'assets/following.svg'
import grabbable from 'assets/grabbable.svg'
import lock from 'assets/lock.svg'
import Logo from 'assets/tipbox_Logo.svg'
import LogoDark from 'assets/tipbox_Logo_dark.svg'
import LogoGrey from 'assets/tipbox_Logo_grey.svg'
import LogoWhite from 'assets/tipbox_Logo_white.svg'
import message from 'assets/message_default.svg'
import messageGrey from 'assets/message_grey.svg'
import messageRed from 'assets/message_red.svg'
import navSearch from 'assets/navSearch.svg'
import navSearchHighlight from 'assets/navSearchHighlight.svg'
import navDashboard from 'assets/navDashboard.svg'
import navDashboardHighlight from 'assets/navDashboardHighlight.svg'
import navProjects from 'assets/navProjects.svg'
import navProjectsHighlight from 'assets/navProjectsHighlight.svg'
import navPersonal from 'assets/navPersonal.svg'
import navPersonalHighlight from 'assets/navPersonalHighlight.svg'
import navTrash from 'assets/navTrash.svg'
import navTrashHighlight from 'assets/navTrashHighlight.svg'
import navFlows from 'assets/navFlows.svg'
import navFlowsHighlight from 'assets/navFlowsHighlight.svg'
import pendingMark from 'assets/pending_mark.svg'
import pendingMarkWhite from 'assets/pending_mark_white.svg'
import playVideo from 'assets/play_video.svg'
import plusLargeGrey from 'assets/plus-large-grey.svg'
import plusLargeWhite from 'assets/plus-large-white.svg'
import plusBoxGrey from 'assets/plusBoxGrey.svg'
import plusBoxPurple from 'assets/plusBoxPurple.svg'
import privateMessage from 'assets/private_message.svg'
import profile from 'assets/profile.svg'
import project from 'assets/project.svg'
import projectDetails from 'assets/project_details.svg'
import pushNotification from 'assets/push_notification.svg'
import pushNotificationV2 from 'assets/push_notification_v2.svg'
import recordMic from 'assets/record_mic.svg'
import recordStop from 'assets/record_stop.svg'
import refreshIcon from 'assets/refresh_icon.svg'
import removeMark from 'assets/remove_mark.svg'
import removeMarkWhite from 'assets/remove_mark_white.svg'
import requestSignature from 'assets/request_signature.svg'
import sendMailGrey from 'assets/send-mail-grey.svg'
import sendMailWhite from 'assets/send-mail-white.svg'
import slackNotification from 'assets/slack_notification.svg'
import slackNotificationV2 from 'assets/slack_notification_v2.svg'
import smsNotification from 'assets/sms_notification.svg'
import smsNotificationV2 from 'assets/sms_notification_v2.svg'
import successMark from 'assets/success_mark.svg'
import successMarkWhite from 'assets/success_mark_white.svg'
import tagGrey from 'assets/tag_grey.svg'
import tagTextRegular from 'assets/tag_textRegular.svg'
import tagTextSoft from 'assets/tag_textSoft.svg'
import teamsNotification from 'assets/teams_notification.svg'
import teamsNotificationV2 from 'assets/teams_notification_v2.svg'
import tipCommentEmptyState from 'assets/tip_comment_empty_state.svg'
import tipCommentEmptyStateDark from 'assets/tip_comment_empty_state_dark.svg'
import tipDoc from 'assets/tip_file.svg'
import tipLogo from 'assets/tip_logo.svg'
import tipLogoDark from 'assets/tip_logo_dark.svg'
import tipPdf from 'assets/tip_pdf.svg'
import tipPpt from 'assets/tip_ppt.svg'
import tipVoice from 'assets/tip_voice.svg'
import tipXlsx from 'assets/tip_xlsx.svg'
import toggleBoxCheckRed from 'assets/toggle_box_check_red.svg'
import toggleBoxCheckGrey from 'assets/toggle_box_check_grey.svg'
import toggleBoxEmpty from 'assets/toggle_box_empty.svg'
import toggleBoxExclamationGrey from 'assets/toggle_box_exclamation_grey.svg'
import toggleBoxExclamationRed from 'assets/toggle_box_exclamation_red.svg'
import trashEmptyState from 'assets/trash_empty_state.svg'
import tutorialStep1 from 'assets/tutorial-step-1.svg'
import tutorialStep2 from 'assets/tutorial-step-2.svg'
import tutorialStep3 from 'assets/tutorial-step-3.png'
import tutorialStep4 from 'assets/tutorial-step-4.svg'
import uploadFailed from 'assets/upload_failed.svg'
import urgent from 'assets/urgent_default.svg'
import urgentGrey from 'assets/urgent_grey.svg'
import urgentOutline from 'assets/urgent_outline.svg'
import urgentRed from 'assets/urgent_red.svg'
import verticalDivider from 'assets/vertical-divider.svg'
import wordDoc from 'assets/word_doc.svg'
import xGreyCircle from 'assets/x-greyCircle.svg'
import xSmall from 'assets/x-small.svg'
import xSmall02 from 'assets/x_small02.svg'
import shootingStar from 'assets/shooting_star.svg'

const images = {
  approval,
  approvalCheck,
  approvalDetails,
  approvalDetailsRed,
  arrowUp,
  assignOthers,
  attachmentClip,
  authBg,
  blank,
  bookmark,
  bookmarkPlus,
  box,
  boxLogo,
  boxLogoDark,
  boxWhite,
  calendar,
  calendarGrey,
  calendarGreyThick,
  calendarHighlight,
  calendarRed,
  calendarRedThick,
  camera,
  chevronRedDown,
  chevronRightBreadcrumb,
  circleCheck,
  circlePlus,
  clockGrey,
  clockRed,
  checkbox,
  checkmark,
  documentSavedCheckmark,
  download,
  dragAndDrop,
  ellipsesBlack,
  emailNotification,
  emailNotificationV2,
  emptyFavorites,
  emptyFlows,
  emptyFollowing,
  emptyPersonal,
  emptyProject,
  emptyProjects,
  emptyRecent,
  emptyTip,
  emptyTrash,
  errorPic,
  exclamationRed,
  exclamationWhite,
  failureMark,
  fileAi,
  fileBmp,
  fileCsv,
  fileDoc,
  fileEps,
  fileFdr,
  fileImg,
  fileInd,
  fileMmx,
  fileMovie,
  fileOne,
  filePdf,
  filePot,
  filePs,
  fileRaw,
  fileRtf,
  fileSvg,
  fileTipbox,
  fileTxt,
  fileUnknown,
  fileWav,
  fileXlsx,
  flowConfirm,
  flowToggleExclamation,
  flowToggleNegative,
  flowTogglePositive,
  flowUrgent,
  flows,
  flowsV2,
  flowsBoltRed,
  folder,
  folderDefault,
  following,
  grabbable,
  lock,
  Logo,
  LogoDark,
  LogoGrey,
  LogoWhite,
  message,
  messageGrey,
  messageRed,
  navSearch,
  navSearchHighlight,
  navDashboard,
  navDashboardHighlight,
  navProjects,
  navProjectsHighlight,
  navPersonal,
  navPersonalHighlight,
  navFlows,
  navFlowsHighlight,
  navTrash,
  navTrashHighlight,
  pendingMark,
  pendingMarkWhite,
  playVideo,
  plusBoxGrey,
  plusBoxPurple,
  plusLargeGrey,
  plusLargeWhite,
  privateMessage,
  profile,
  project,
  projectDetails,
  pushNotification,
  pushNotificationV2,
  recordMic,
  recordStop,
  refreshIcon,
  removeMark,
  removeMarkWhite,
  requestSignature,
  sendMailGrey,
  sendMailWhite,
  slackNotification,
  slackNotificationV2,
  smsNotification,
  smsNotificationV2,
  successMark,
  successMarkWhite,
  tagGrey,
  tagTextRegular,
  tagTextSoft,
  teamsNotification,
  teamsNotificationV2,
  tipCommentEmptyState,
  tipCommentEmptyStateDark,
  tipDoc,
  tipLogo,
  tipLogoDark,
  tipPdf,
  tipPpt,
  tipVoice,
  tipXlsx,
  toggleBoxCheckRed,
  toggleBoxCheckGrey,
  toggleBoxEmpty,
  toggleBoxExclamationGrey,
  toggleBoxExclamationRed,
  trashEmptyState,
  tutorialStep1,
  tutorialStep2,
  tutorialStep3,
  tutorialStep4,
  urgent,
  urgentGrey,
  urgentOutline,
  urgentRed,
  uploadFailed,
  verticalDivider,
  wordDoc,
  xGreyCircle,
  xSmall,
  xSmall02,
  shootingStar,
}

export default images
