import { useState, useEffect } from 'react'
import { Box, Flex, Text, Button, useBreakpointValue } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { useQuery } from '@apollo/client'
import { uniqBy } from 'lodash'
import { useNodeMenuActions } from 'features/node/hooks'
import { GET_NODE, USERS, GET_USER_GUEST_SHARE_MEMBERS } from 'features/graphql'
import { useAppSlice } from 'features/redux'
import { AvatarsTooltip, MessageInput } from 'components/elements'
import { User, Node } from 'types/graphqlSchema'
import EditSharedMembers from './EditSharedMembers'
import ShareRecipientSelect from './ShareReceipientSelect'

interface UserSelectOption extends User {
  value: string
  label?: string
  __isNew__?: boolean
}

interface ShareModalShareTabProps {
  shareState: {
    sentState: string
    sharerMessage: string
    sent: boolean
  }
  setShareState: React.Dispatch<React.SetStateAction<ShareModalShareTabProps['shareState']>>
  node: Node
  containerHeight: number | string
  setContainerHeight: React.Dispatch<React.SetStateAction<number | string>>
  recipientsToList: User[]
  setRecipientsToList: React.Dispatch<React.SetStateAction<User[]>>
  textAreaExpanded: boolean
  setTextAreaExpanded: React.Dispatch<React.SetStateAction<boolean>>
  selectOpen: boolean
  setSelectOpen: React.Dispatch<React.SetStateAction<boolean>>
  showEditPane: boolean
  setShowEditPane: React.Dispatch<React.SetStateAction<boolean>>
}

const ShareModalShareTab = ({
  shareState,
  setShareState,
  node,
  containerHeight,
  setContainerHeight,
  recipientsToList,
  setRecipientsToList,
  textAreaExpanded,
  setTextAreaExpanded,
  selectOpen,
  setSelectOpen,
  showEditPane,
  setShowEditPane,
}: ShareModalShareTabProps) => {
  // Chakra and Visual
  const isMobile = useBreakpointValue({ base: true, md: false })
  const [textAreaExtraHeight, setTextAreaExtraHeight] = useState(0)

  // GraphQL
  const { loading, error, data: usersQueryData } = useQuery(USERS)
  const { data: guestUsersQueryData } = useQuery(GET_USER_GUEST_SHARE_MEMBERS)

  const { shareNode } = useNodeMenuActions()
  const { user } = useAppSlice()
  // Share Recipients
  const [usersData, setUsersData] = useState<User[]>()
  const [userSelectValue, setUserSelectValue] = useState<UserSelectOption[]>()
  const [userSelectOptions, setUserSelectOptions] = useState<UserSelectOption[]>()

  // Manage height of the modal
  useEffect(() => {
    if (!selectOpen && recipientsToList && recipientsToList.length > 0 && textAreaExpanded) {
      setContainerHeight(328)
      return
    }
    if (!selectOpen && recipientsToList && recipientsToList.length > 0) {
      setContainerHeight(258)
      return
    }
    setContainerHeight(258)
    setShowEditPane(false)
  }, [recipientsToList?.length, textAreaExtraHeight, textAreaExpanded])

  // Populate Recipients To List
  useEffect(() => {
    if (node && node?.sharedMembers) {
      const sharedMembersToList: User[] = node.sharedMembers
        .map((sm) => sm?.sharedWith)
        .filter((mem): mem is User => Boolean(mem))
      setRecipientsToList(sharedMembersToList)
    }
  }, [node, node?.sharedMembers])

  // Populate Users Select Options (userSelectOptions)
  useEffect(() => {
    if (!loading && !error) {
      const guestUsers = guestUsersQueryData?.me?.shareEmailSuggestions || []
      const orgUsers = usersQueryData.users
      const users = uniqBy([...orgUsers, ...guestUsers], 'id')
      setUsersData(users)
      const userDataWithEmailValue = users.map((user) => ({
        ...user,
        value: user.email,
        label: user.firstName + ' ' + user.lastName,
      }))
      setUserSelectOptions(userDataWithEmailValue.filter((u) => u.id !== user.id))
    }
    if (error) {
      console.error('An error occurred when setting the select options:', error.message)
    }
  }, [loading, error, usersQueryData, guestUsersQueryData?.me?.shareEmailSuggestions])

  useEffect(() => {
    if (shareState.sentState.length > 0) {
      const timer = setTimeout(() => {
        setShareState((prev) => ({ ...prev, sentState: '' }))
      }, 4000)
      return () => clearTimeout(timer)
    }
  }, [shareState.sentState])

  return (
    <motion.div
      layout
      key="shareModal"
      aria-label="Share"
      initial={{ height: 30, opacity: 0 }}
      animate={{
        height: containerHeight,
        opacity: 1,
      }}
      transition={{
        type: 'ease',
        duration: 0.7,
        opacity: { delay: 0.6 },
      }}
      style={{
        borderRadius: '6px',
        display: 'flex',
        flexDirection: 'column',
        gap: isMobile ? '.5rem' : '1.5rem',
        width: '100%',
        fontSize: isMobile ? '12px' : '14px',
        overflow: 'hidden',
      }}
    >
      {showEditPane && (
        <EditSharedMembers
          node={node}
          setRecipientsToList={setRecipientsToList}
          recipientsToList={recipientsToList}
          setShowEditPane={setShowEditPane}
        />
      )}
      {!showEditPane && (
        <>
          {/* SELECT RECIPIENTS */}
          <Flex align={'center'} gap={3} w="full">
            <Text color="textBlack" mx={6}>
              Recipients:
            </Text>
            <Flex borderBottom="1.5px solid var(--chakra-colors-borderLight)" flex={1} w="full">
              <ShareRecipientSelect
                isOpen={selectOpen}
                setIsOpen={setSelectOpen}
                userSelectOptions={userSelectOptions}
                usersData={usersData}
                selectValue={userSelectValue}
                setSelectValue={setUserSelectValue}
                setRecipientsToList={setRecipientsToList}
                recipientsToList={recipientsToList}
                menuHeight={recipientsToList && recipientsToList?.length > 0 ? '200px' : '140px'}
              />
            </Flex>
          </Flex>
          <Flex flexDir="column" ml={6}>
            <Text color="textBlack">Optional</Text>
            {/* Message */}
            <MessageInput
              value={shareState.sharerMessage}
              onChange={(value) =>
                setShareState((state) => ({
                  ...state,
                  sharerMessage: value,
                }))
              }
              setTextAreaExpanded={setTextAreaExpanded}
            />
          </Flex>
          {!selectOpen && (
            <>
              <Flex id="Share Receipients Lists" flexDir={'column'} pl={6}>
                <Box pb={2}>{node?.owner && <AvatarsTooltip users={[node.owner]} caption={'Owner'} />}</Box>
                {!!recipientsToList?.length && (
                  <AvatarsTooltip
                    users={recipientsToList}
                    clickAction={() => {
                      setShowEditPane((prev) => !prev)
                    }}
                  />
                )}
              </Flex>
            </>
          )}
        </>
      )}
    </motion.div>
  )
}

export default ShareModalShareTab
