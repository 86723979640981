import { useEffect, useState } from 'react'
import {
  Flex,
  Button as ChakraButton,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { Toast, Button } from 'components/elements'
import { minDelay } from 'helpers/utils'
import { EntityType } from 'types/graphqlSchema'
import TagsGrid from './TagsGrid'
import { useTags } from '../hooks'

interface TagsModalProps {
  isOpen: boolean
  onClose: (updatedTags?: string[]) => void
  entityId: string
  entityName: string
  entityType: EntityType
  nodeType?: string
}

export default ({ isOpen, onClose, entityId, entityName, entityType, nodeType }: TagsModalProps) => {
  const {
    attachedTags,
    previousTags,
    availableTags,
    setAttachedTags,
    addTag,
    removeTag,
    fetchTags,
    fetchAvailableTags,
  } = useTags()
  const isMobile = useBreakpointValue({ base: true, md: false })
  const [containerHeight, setContainerHeight] = useState(370)
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (!entityId || !entityType) return
    fetchAvailableTags()
    if (isOpen) fetchTags(entityId, entityType)
  }, [isOpen, entityId, entityType])

  // Manage height of the modal
  useEffect(() => {
    if (attachedTags.length <= 3) {
      setContainerHeight(300)
    } else if (attachedTags.length > 3 && attachedTags.length <= 6) {
      setContainerHeight(350)
    } else if (attachedTags.length > 6) {
      setContainerHeight(370)
    }
  }, [attachedTags.length])

  const handleSubmit = async (event) => {
    event.preventDefault()
    setIsSubmitting(true)
    if (!entityId) {
      console.error('ID not found. Aborting tag addition.')
      return
    }
    if (previousTags && previousTags.length > 0) {
      const tagsToRemove = previousTags
        .filter((prevTag) => prevTag?.name !== attachedTags.find((tag) => tag === prevTag?.name))
        .map((tag) => tag?.name)
      for (const tagName of tagsToRemove) {
        const tag = availableTags.find((tag) => tag.name === tagName)
        if (tag) {
          const tagId = tag.id
          try {
            await removeTag(entityId, entityType, tagId)
          } catch (error) {
            console.error(`Error removing tag '${tagName}':`, error)
          }
        } else {
          console.warn(`Tag '${tagName}' not found in the list of available tags.`)
        }
      }
    }
    if (attachedTags.length > 0) {
      for (const tagName of attachedTags) {
        const tag = availableTags.find((tag) => tag.name === tagName)
        if (tag && (!previousTags || !previousTags.find((pt) => pt?.id === tag.id))) {
          const tagId = tag.id
          try {
            await addTag(entityId, entityType, tagId)
          } catch (error) {
            console.error(`Error adding tag '${tagName}':`, error)
          }
        } else {
          console.warn(`'${tagName}' already present.`)
        }
      }
    }
    await minDelay(fetchTags(entityId, entityType), 1000)
    Toast.show({
      icon: 'check',
      message: `Tags updated.`,
      option: { type: 'success', theme: 'light', autoClose: 3000 },
    })
    setIsSubmitting(false)
    onClose(attachedTags)
  }
  const handleCancel = async () => {
    if (previousTags.length > 0) {
      await setAttachedTags(previousTags.map((prev) => prev.name))
    }
    onClose()
  }

  if (!entityId || !entityType) return null

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        display={'flex'}
        flexDir={'column'}
        justifyContent={'center'}
        bg={'white'}
        color="textBlack"
        fontFamily={'Inter'}
        w={'600px'}
        maxW={'80%'}
        px={4}
        py={6}
      >
        <ModalHeader py={0} display="flex" flexDir="column" fontSize={18} mt={2}>
          <Text color="textBlack" mb={'1.25rem'}>
            {entityName}
          </Text>
          <Text
            fontWeight={500}
            color="#1A1A1A"
            fontSize=".875rem"
            pb=".5rem"
            borderBottom="3px solid black"
            maxW="min-content"
          >
            Tags
          </Text>
          <hr
            color="#E9EAEC"
            style={{
              margin: '0 0 1rem',
              height: '0',
              padding: '0',
              width: '100%',
            }}
          />
        </ModalHeader>
        <ModalCloseButton color="textBlack" onClick={handleCancel} />
        <form onSubmit={handleSubmit} onReset={handleCancel}>
          <ModalBody display={'flex'} flexDirection={'column'} mx={4} px={1} rounded={6} border="1px solid #E9EAEC">
            <motion.div
              layout
              initial={{ height: 30, opacity: 0 }}
              animate={{
                height: containerHeight,
                opacity: 1,
              }}
              transition={{
                type: 'ease',
                duration: 0.7,
                opacity: { delay: 0.6 },
              }}
              style={{
                borderRadius: '6px',
                display: 'flex',
                flexDirection: 'column',
                gap: isMobile ? '.5rem' : '1.5rem',
                width: '100%',
                fontSize: isMobile ? '12px' : '14px',
                padding: '0 8px',
              }}
            >
              <Grid
                templateColumns={isMobile ? 'repeat(3, 1fr)' : 'repeat(3, 1fr)'}
                gap={isMobile ? 2 : 4}
                borderBottom="1px solid #E9EAEC"
                pb={2}
                minH="2.55rem"
                maxH="8.75rem"
                overflowY="scroll"
                overflowX={'hidden'}
                css={{
                  '&::-webkit-scrollbar': {
                    width: '0',
                  },
                  '&::-webkit-scrollbar-track': {
                    width: '0',
                  },
                }}
              >
                {attachedTags.map((tag) => (
                  <ChakraButton
                    key={tag}
                    variant={'unstyled'}
                    minW="max"
                    value={tag}
                    bg="accent"
                    color="textHighlight"
                    fontWeight={500}
                    display={'flex'}
                    justifyContent={'flex-start'}
                    maxW={'max-content'}
                    px={isMobile ? 2 : 4}
                    cursor="default"
                    h="2rem"
                    leftIcon={
                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.12564 0C5.59897 0 6.05291 0.188029 6.38761 0.522724L11.4773 5.61239C12.1742 6.30936 12.1742 7.43936 11.4773 8.13633L8.13633 11.4773C7.43936 12.1742 6.30936 12.1742 5.61239 11.4773L0.522724 6.38761C0.188029 6.05291 0 5.59897 0 5.12564V1.78469C0 0.799033 0.799033 0 1.78469 0H5.12564ZM3.5 2C2.67157 2 2 2.67157 2 3.5C2 4.32843 2.67157 5 3.5 5C4.32843 5 5 4.32843 5 3.5C5 2.67157 4.32843 2 3.5 2Z"
                          fill="#FFF"
                        />
                      </svg>
                    }
                  >
                    {tag}
                  </ChakraButton>
                ))}
                {attachedTags.length < 2 && (
                  <Flex
                    fontWeight={500}
                    fontSize={13}
                    minW="max"
                    color="#666"
                    h="full"
                    justifyContent="center"
                    align="center"
                  >
                    Click below to add a tags to this {nodeType}.
                  </Flex>
                )}
              </Grid>
              <TagsGrid
                tagsList={availableTags.map((tag) => tag.name)}
                setAttachedTags={setAttachedTags}
                attachedTags={attachedTags}
              />
            </motion.div>
          </ModalBody>
          <ModalFooter>
            <Flex gap={4} flex={1} align="end" justify="end">
              <Button
                variant="defaultButton"
                color="1A1A1A"
                borderColor="white"
                bg="white"
                _hover={{ borderColor: '#1A1A1A' }}
                type="reset"
                label="Cancel"
              />
              <Button
                variant="defaultButton"
                disabled={previousTags.length === 0 && attachedTags.length === 0}
                type="submit"
                label={'Save'}
                isLoading={isSubmitting}
              />
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
