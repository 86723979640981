import { useEffect } from 'react'
import { Box, Flex, Text, Image, Button } from '@chakra-ui/react'
import { capitalize } from 'lodash'
import { useNodeMenuActions } from 'features/node/hooks'
import { useAppSlice } from 'features/app'
import { menuActions } from 'helpers/constant'
import { resourceType } from 'helpers/utils'
import { usePermissions } from 'hooks'
import { images } from 'theme'
import { EntityType, Flow, Node } from 'types/graphqlSchema'
import TagsModal from './TagsModal'
import { useTags } from '../hooks'

interface TagsSectionProps {
  entity: Node | Flow
  entityType: EntityType
  isEditable?: boolean
  paddingY?: string
  darkBg?: boolean
}

export default ({ entity, entityType, isEditable = true, paddingY = '12px', darkBg }: TagsSectionProps) => {
  const { openModal, setOpenModal } = useNodeMenuActions()
  const { hasPermission } = usePermissions()
  const { user } = useAppSlice()
  const { loading, previousTags, fetchTags } = useTags()
  const nodeType = () => {
    if (entityType === EntityType.Node) {
      const node = entity as Node
      return capitalize(resourceType(node.type) ?? '')
    }
  }
  const disabledUpdateTags = () => {
    if (entityType === EntityType.Node) {
      return !hasPermission('update', entity as Node)
    } else if (entityType === EntityType.Flow) {
      return user.id !== (entity as Flow).requester?.id
    }
    return true
  }

  useEffect(() => {
    if (!entity || !entity.id || !entityType) return
    fetchTags(entity.id, entityType)
  }, [entity, entityType])

  if (!entity || loading) return null

  return (
    <Box py={paddingY}>
      <Flex fontSize="15px">
        <Image src={darkBg ? images.tagTextRegular : images.tagGrey} alt="tag" />
        <Text pl={1} color="textRegular">
          Tags
        </Text>
      </Flex>
      {isEditable && (
        <Button
          color={darkBg ? 'textSoft' : 'textBlack'}
          fontSize="15px"
          variant="inlineLinkButton"
          fontWeight="400"
          isDisabled={disabledUpdateTags()}
          onClick={() => setOpenModal(menuActions.tag)}
        >
          + Add / Edit
        </Button>
      )}
      {!loading && (
        <Box pt={2}>
          {previousTags.map((tag) => (
            <Flex fontSize="15px" key={tag.id}>
              <Image src={darkBg ? images.tagTextSoft : images.tagGrey} alt="tag" />
              <Text pl={1} color={darkBg ? 'textSoft' : 'textBlack'}>
                {tag.name}
              </Text>
            </Flex>
          ))}
        </Box>
      )}
      {/* TAGS */}
      <TagsModal
        nodeType={nodeType()}
        entityName={entity?.name || ''}
        entityType={entityType}
        entityId={entity.id || ''}
        isOpen={openModal === menuActions.tag}
        onClose={() => {
          fetchTags(entity.id, entityType)
          setOpenModal('')
        }}
      />
    </Box>
  )
}
