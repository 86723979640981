import {
  Center,
  Text,
  Button,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useDisclosure,
  useColorModeValue,
  Portal,
} from '@chakra-ui/react'
import { Node } from 'types/graphqlSchema'
import { icons } from 'theme'
import PopoverOptionButton from './PopoverOptionButton'

const ProjectPopover = ({
  options,
  selected,
  onSelect,
}: {
  options: Node[]
  selected?: Node
  onSelect: (node?: Node) => void
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const currentProjectName = selected ? selected?.name || 'Untitled' : 'All Projects'
  return (
    <Popover isLazy closeOnBlur onClose={onClose} variant="autoWidth" placement="bottom-start" isOpen={isOpen}>
      <PopoverTrigger>
        <Button
          variant="transparentButton"
          p={0}
          onClick={onToggle}
          _focusVisible={{ boxShadow: '0 0 0 1px var(--chakra-colors-borderFocus)' }}
        >
          <Center>
            <Text color={useColorModeValue('black', 'white')} fontSize="20px" fontFamily="Inter" mr="16px">
              {currentProjectName}
            </Text>
            <Icon as={icons.FaChevronDown} w="12px" h="12px" color={useColorModeValue('textBlack', 'textHighlight')} />
          </Center>
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent p={0} bg="textHighlight" border="none">
          <PopoverBody display="flex" flexDir="column" bg="textHighlight" borderRadius="8px" p={0}>
            <PopoverOptionButton
              title="All Projects"
              selected={!selected}
              onClick={() => {
                onSelect(undefined)
                onClose()
              }}
            />
            {options.map((option) => (
              <PopoverOptionButton
                key={option?.id}
                title={option?.name || 'Untitled'}
                selected={selected?.name === option?.name}
                onClick={() => {
                  onSelect(option)
                  onClose()
                }}
              />
            ))}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export default ProjectPopover
